@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.AboutPage{@include sec-default();
  .FlyerLayout{@include sec-default(); padding:140px 0 280px;
    position: relative; z-index: 0;@include sec-bgimage("./images/flyerBg.jpg");background-size:cover;
    &::before{@include before(); background-color: rgba($black,.9);  background-color: rgba(17,17,17,.93);}
    &::after{@include after();background-color:$white;right: 0;height:190px; transform: skewY(-6deg);transform-origin: top right;}

    .flContent{@include sec-default(); position: relative; z-index: 0;display: flex; align-items: center;margin-top:20px;}
    .row2mm::before{@include abs(); width:1px; height:100%; left:61%; background-color:$white;}
    .row1{display:block;text-align: center;}

    .boxLg, .boxSm{display: inline-block; vertical-align: top;}
    // .boxLg{width:61%;padding-right:3%;}
    // .boxSm{width:35%;margin-left:4%;}
    .boxLg{width:65%;padding-right:3%;margin-left:-3%;}
    .boxSm{width:33%;margin-left:3%;}
    .left{float:left;}
    .right{float:right;}
    .btop{border-top:1px solid $white; padding-top:30px;margin-top:40px;}

    &,h1,h2,h3,p,li{color:$white;
      span{color:$red;}
    }
    
    p.title{font-size:2.1rem;line-height:1.3em;font-weight:bold;}
    h2, p.sub{font-size:1.4rem;letter-spacing: 5px; text-transform: uppercase;}
    p, li{line-height: 1.9em; font-size:19px;}
    li{line-height: 1.6em;font-weight:500;margin-bottom:20px;}
    
    p.sub2{font-size:1.45rem;letter-spacing: 5px; text-transform: uppercase;line-height: 1.5em; margin-bottom:30px;
      span{letter-spacing: inherit;}
    }

    .iconLine{@include sec-default();margin:5px 0 15px;}
    .icon{height:20px;}
    .iconLg{height:45px;}
    
    // with LG icon and boxXl ONLY
    // p.title{margin-top:-12px;}
    .boxXl{width:100%; text-align: center; position:absolute; top:30px; left:0;}

    @media (min-width:1830px){
      &::after{height:230px;}
    }

    @include bp(tw) {
      // .row2mm::before{left:55%;}
      // .boxLg{width:55%;}
      // .boxSm{width:40%;}

      p.title{font-size:1.9rem;}
      h2, p.sub{font-size:1.25rem;}
      p.sub2{font-size:1.3rem;}
    }

    @include bp(tb) {padding:80px 0;
      // .row2mm::before{left:48%;}
      // .boxLg{width:49%;}
      // .boxSm{width:47%;}
      .row2mm::before{left:47.7%;}
      .boxLg{width:49%;margin-left:0;}
      .boxSm{width:47%;}

      p.title{font-size:1.6rem;}
      h2, p.sub{font-size:1.15rem;}
      p.sub2{font-size:1.15rem;}
      p,li{font-size: 18px;}

      &.AboutFly{padding:80px 0 230px;}
    }

    @include bp(br) {
      p.sub2{font-size:1.05rem;letter-spacing: 3px;}
      p.title{font-size:1.3rem;}
    }

    @include bp(md){
      &.AboutFly{padding:80px 0 130px;}
      &::after{height:110px;}
    }

    @include bp(og) {padding:30px 0;
      &.AboutFly{padding:30px 0 60px;}
      &::after{height:110px;}
      .flContent{display: block;margin:0 0 60px;}

      .row2mm::before{display: none;}
      .boxLg,.boxSm{@include sec-default(); padding:0; margin: 0; text-align: center;}
      .boxLg{margin-bottom: 15px;}

      p.title{font-size:1.15rem;}
      h2, p.sub{font-size:1.05rem;}
      p.sub2{font-size:1rem; letter-spacing: 3px;}
      .boxXl{position: relative; top:auto;}
      li{margin-bottom:15px; line-height: 1.45em;}
    }

    @include bp(oc) {
      &::after{height:90px;}
      .flContent{margin:0 0 40px;}
    }
  }

  .HistorySec.XX{@include sec-default();text-align: center; position: relative;z-index:1;padding:0px 0 60px;
    &::before{@include before();@include sec-bgimage('./images/planeBg.png');background-size:180px;background-position:top center;opacity: .35;top:-70px;}

    .titleRow{@include sec-default();margin-bottom:10px;}
    .mid{@include sec-bgimage2(); background-image: url("../../assets/images/MARCsymbol.png"); background-size:130px;}
    .hsWrap{@include sec-default();position: relative;z-index:0;}
    .hsRow{@include sec-default();}
    .hsImg{margin:10px 0 0px;position: relative;z-index:0;
      img{width:65%;@include center-block();opacity:.05;}
      &::after{@include abs();width:150px;height:150px;left:50%;margin-left:-75px;top:50%;margin-top:-65px;@include sec-bgimage('./images/MARCsymbolBg.png');background-size:130px;z-index:1;}
      @keyframes pulse-black {
        0% {transform: scale(0.95);}
        70% {transform: scale(1);}      
        100% {transform: scale(0.95);}
      }
    }
    .hsBtm{padding:205px 0 140px;position: relative;}
    .hsItem{display:inline-block;}
    .hsiFull{@include center-block();max-width:400px;margin-bottom:50px;}
    .hsi2{width:37%;margin:0 5%;vertical-align: top;}

    // .textLink{@include sec-default();color:$red;font-size:16px;margin-top:-7px;}
    .textLink{color:$red;font-size:16px;}
    p.h3{text-align:center; font-weight:bold; font-size:1.4rem; line-height: 1.3em;
      span{color:$red;}
    }
    p.year{margin-bottom:0px;font-weight:bold;}
    .yearSlide li{@include sec-default();}
    // .dotList li a{background-color:$black;} //li.uk-active

    @media (min-width:1025px) {
      .hsImg::after{transform: scale(1);animation: pulse-black 2s infinite;}
    }

    @media (min-width:1201px){
      .hsi4,.hsiLast{position:absolute;width:25%;}
      .hsi4a{left:-2%;top:0;}
      .hsiLast{right:-3%;width:30%;top:-20px;}
      .hsi4b{left:15%;top:35%}
      .hsi4c{bottom:20px;left:50%;margin-left:-12.5%;}
      .hsi4d{right:15%;top:35%;}
    }

    @include bp(tw){margin-top:-50px;
      .titleRow{margin-bottom:10px;}
      .hsi1{margin-bottom:20px;}
      .hsi2{width:40%;}
      .hsBtm{padding:20px 0 40px;}
      .btmwrap{max-width:930px;@include center-block();}
      .hsi4a, .hsi4b, .hsi4c{width:40%;max-width:280px; margin:-30px 0 0;}
      .hsi4a{float:left;}
      .hsi4b{float:right;}
      .hsi4c {margin-top:40px;}
      .hsi4d,.hsiLast{display:inline-block;vertical-align:top;margin:10px 15px 0;}
      .hsi4d{width:40%;max-width:280px;}
      .hsiLast{width:55%;max-width:475px;float:right}
      .clearfix{display: none;}

      .hsImg{
        &::after{width:110px;height:110px;margin-left:-55px;background-size:99%;}
        img{width:55%;}
      }    
    }

    @include bp(tb){
      p.h3{font-size:1.3rem;}
      .mid{background-size:110px;}
    }

    @media (max-width:959px){
      .hsi2{width:48%;margin:0;}
      .hsi2a{float:left;}
      .hsi2b{float:right;}
      .btmwrap{max-width:650px;}
      .hsi4a,.hsi4b,.hsi4c,.hsi4d,.hsiLast{width:100%;}
      .hsi4a,.hsi4b,.hsi4c{margin:0px 0 20px;}
      .hsi4a,.hsi4d,.hsiLast{@include center-block();}
      .hsi4a,.hsi4d{max-width:100%;}
      .hsi4d,.hsiLast{margin:0 auto;}
      .hsi4d{margin-bottom:20px;}
      p.year{@include sec-default();}
    }

    @include bp(md){margin-top:0;padding:0px 0 50px;
      .hsImg{margin:0;
        &::after{width:60px;height:60px;margin-left:-30px;margin-top:-30px;}
        img{width:55%;}
      }  
    }

    @include bp(og){
      &::before{top:-50px;}
      p.h3{font-size:1.15rem;}
      
      .hsBtm{padding:15px 0 0px;}
      .hsi2{width:100%;}
      .hsi1,.hsi4b,.hsi4c,.hsiLast{max-width:100%;}
      .hsItem {margin:0 0 15px !important;
        p{margin-bottom:0;}
        p.year{line-height:1.2em;}
      }
    }

  }

  .mission{@include sec-default();  position: relative; z-index: 0; padding:40px 0;
    &::before{@include abs();}

    .hlf1,.hlf2{display: inline-block;}
    .hlf1{float: right;width:70%;}
    .hlf2{float: left; width:25%;margin-top:1%;}

    p.sig{font-size:32px; font-weight: bold; color:$red;}
    .hlf1 p span{color:$red; font-weight:400;}


    &.altVerstion{
      .hlf1{float: right;width:70%;}
      .hlf2{float: left; width:30%;margin-top:1%;}
    }
  }

  .fwText{@include sec-default(); padding:60px 0 80px; text-align: center;
    p{font-size:22px; color:$red;}
    p span{font-weight: 500;}
  }
}

.StackLayout.About .scStory{z-index: 2;
  p.title {line-height:1.3em;
    span{font-weight:normal;letter-spacing:4.7px;font-size:16px;text-transform:uppercase;}
  }
  @media (min-width:1830px) {
    .storyImage{max-height:600px;width:auto;right:auto;margin-left:3%;}
  }
  @media (min-width:1316px) and (max-width:1500px){
    .storyImage{width:47%;}
  }

  @include bp(tb){p.title span{font-size:15px;letter-spacing:4px;}}
  @include bp(br){.siMobile{width:85%;}}
}

.scRow.scStoryRev{z-index: 2;
  margin-top:0;padding:120px 0 90px;
  &::before{display: none;}
  .storyText,.storyImage{display: inline-block;}
  .storyText{position:relative;float:right; width:40%;margin-top:10%; margin-right:2%;}
  .storyImage,.ilMap{float:left; width:48%;margin-left:2%;}
  // .ilMapMobile{display: none;}

  .textbox,.review{position: relative;}
  .textbox::before,.review::before{@include before();width:2px; background-color:$red;left:-8%;}

  @include bp(tx){
    .storyText{margin-top:7%;}
  }

  @include bp(tw){padding-bottom:50px;
    .inside{width:90%;}
    .storyText{width:42%;margin-right:0;margin-top:13%;}
    .storyImage,.ilMap{float:left; width:52%;margin-left:0;}
  }

  @include bp(tb){padding:0 0 60px;
    .inside{width:95%;}
    .storyText{width:43%;}
    .textbox::before,.review::before{left:-5%;}
  }

  @include bp(br){//margin:0px 0 15px;
    &::before{display: none;}
    .inside{max-width:700px;}
    .storyText,.storyImage,.ilMap{@include sec-default();}
    .storyText{margin-top:0px;}
    .textbox,.review{padding-left:25px;}
    .textbox::before,.review::before{left:0;}
  }

  @include bp(og){padding-bottom:40px}
  @include bp(oc){padding:10px 0 25px}
}
.review{
  p{font-size:1.25rem;
    span{font-weight:500;}
  }
  p span.name{font-size:1rem;}

  @include bp(tw){p{font-size:1.15rem;}}
  @include bp(tb){
    p{font-size:1.05rem;}
    p span.name{font-size:.95rem;}
  }

  @include bp(og){
    p{font-size:1rem;}
    p span.name{font-size:.92rem;}
  }
}




.HistorySec.HistoryInline{@include sec-default();text-align: center; position: relative;z-index:1;padding:0 0 90px;
  &::before{@include before();@include sec-bgimage('./images/planeBg.png');background-size:180px;background-position:top center;opacity: .35;top:-70px;}

  .titleRow{@include sec-default();margin-bottom:10px;}
  .hsWrap{@include sec-default();position: relative;z-index:0;}
  .hsRow{@include sec-default();}
  .hsImg{margin:10px 0 30px;position: relative;z-index:0;
    img{width:50%;@include center-block();opacity:.05;}
    &::after{@include abs();width:100px;height:100px;left:50%;margin-left:-50px;top:50%;margin-top:-50px;@include sec-bgimage('./images/MARCsymbolBg.png');background-size:90px;z-index:1;}
    @keyframes pulse-black {
      0% {transform: scale(0.95);}
      70% {transform: scale(1);}      
      100% {transform: scale(0.95);}
    }
  }

  .textLink{color:$red;font-size:16px;}
  p.h3{text-align:center; font-weight:bold; font-size:1.4rem; line-height: 1.3em;
    span{color:$red;}
  }
  p.year{margin-bottom:0px;font-weight:bold;}

  
  .hsItem{display:inline-block;}
  .smItem{width:25%;}
  .lgItem{width:45%;}

  .hsi4{float:left;}
  .hsi5{margin-top:70px;margin-left:4%}
  .hsiLast{width:33%;float:right;margin-right:-5%;margin-top:-10px;}
  .yearSlide li{@include sec-default();}
  .dotList{margin-top:0 !important;margin-bottom:0 !important;
    // li a{background-color:$black;} //li.uk-active
  }

  @media (min-width:1025px) {.hsImg::after{transform: scale(1);animation: pulse-black 2s infinite;}}
  @include bp(tw){margin-top:-50px;.titleRow{margin-bottom:10px;}}

  @media (max-width:1050px){
    .hsi4{margin-left:2%}
    .hsi5{margin-top:70px;margin-left:4%}
    .hsiLast{width:33%;float:right;margin-right:-2%;margin-top:-10px;}
  }

  @include bp(tb){p.h3{font-size:1.3rem;}}

  @include bp(br){padding-bottom:30px;
    .medDef,.med2Def{max-width:400px;}
    .hsItem,.smItem,.lgItem{@include sec-default();margin:0 0 10px;}
    .hsi3,.hsi5{margin-bottom:30px;}
    .hsImg{display:none;}
  }

  @include bp(md){margin-top:0;}

  @include bp(og){padding-bottom:15px;
    &::before{top:-50px;}
    p.h3{font-size:1.15rem;}
  }

}