@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;800&family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

*, *::before, *::after {box-sizing: border-box;}
body{margin:0;}
html {line-height: 1.6em; font-size: 18px; color:$black;font-family:$body; font-weight: 300;
  h1, h2, h3, h4, h5, p, li, a, span {color: $black; line-height: 1.6em;font-family:$body; letter-spacing:.5px; font-weight:300;margin: 0px 0 15px;}
  p{font-size: 1rem;}
  span{color:inherit;font-weight:inherit;font-family:inherit;}
  
  h1{font-size: 2.1rem; line-height: 1.3em; font-weight:800;}
  h2{font-size:1.5rem;line-height: 1.3em;}
  h3{font-size: 1.2rem;}

  @include bp(tb) {
    h1{font-size: 1.8rem;}
    h2{font-size:1.3rem;}
    h3{font-size: 1.2rem;}
  }

  @include bp(br) {h1{font-size: 1.65rem;}}
  @include bp(md) {h1{font-size: 1.65rem;}}
  @include bp(og) {&,p{font-size: 17px;}}
}

a {text-decoration: none;
  &:hover {opacity: .8; text-decoration: none; @include ease(.3s);}
}
button{background: none; border:none; border-radius:0; color:inherit; font-weight:inherit; font-family: inherit;
  &:hover{cursor: pointer; color:inherit !important;}
}
img{@include center-block();}
ul {margin:0; list-style-type: none; padding-left: 0;}
li{list-style-type: none;}

.marcWrap{@include sec-default(); position:relative;}
.Content{@include sec-default();
  @media (min-width:992px) {min-height:55vh;}
  @media (min-width:1024px) {min-height:65vh;}
}

.ToTopBtn{
  svg{margin-bottom:4px;}

  @include bp(tl) {
    bottom:50px !important;
    right:15px !important;
  }

  @include bp(br) {bottom:15px !important;background-color:$red !important;}
  @include bp(oc) {bottom:45px !important;}
}

.modalDialog{width:99%;max-width:950px !important;padding:40px 30px;
  &::before{@include before();width:40%;background-image:linear-gradient($red, $red3);}

  .modalCol{display: inline-block;}
  .titleLine{width:55px;height:2px;margin-bottom:40px;}
  .mc1{width:38%; float:left;padding:40px 0 0 0px;
    .titleLine{background-color:rgba($white,.75);}
    p,a{color:$white;font-weight:700;text-transform: capitalize;}
    p{font-size:20px;text-shadow: 0px 1px 2px rgba($red3,.4);line-height:1.4em;}
    p.p1{margin-bottom:30px;max-width:300px;}
    p a{font-size: 22px;}
  }
  .mc2{width:54%;margin-left:6%;
    .titleLine{background-color:rgba($black,.5);display:none;}
    p{font-size:17px;}
  }
  
  p.h3{font-weight:800;font-size:1.5em;text-transform:uppercase;margin-bottom:5px;}
  p.h4{font-weight:bold;font-size:1.25em;line-height:1.1em;color:$red2;}
  p.h4 br{display:none;}
  p.preCta{font-weight:500;
    a{font-weight:bold;}
  }
  br.resp,br.maybe{display:none;}

  @media (min-width:1024px){p.preCta{width:70%;@include center-block();}}
  @include bp(br){width:90%;
    &::before{width:42%;}
    .mc1 p{font-size:19px;}
    .mc2{width:55%;margin-left:0;float:right;
      p{font-size:17px;}
    }

    p.h3{font-size:1.35em;}
    p.h4{font-size:1.1em;}
    p.h4 br{display:block;}
    br.maybe{display:block;}
  }

  @media (max-width:792px){
    br.maybe{display:none;}
    br.resp{display:block;}
  }

  @include bp(og){padding:0;
    &::before{display: none;}
    .mc1,.mc2{@include sec-default();text-align: center;}
    .mc1{padding:20px 20px 10px;background-image:linear-gradient($red, $red3);
      p:not(.h3){font-size:18px;max-width:350px;@include center-block();}
      p.p1{margin-bottom:10px;}
      p a{font-size:20px;}
    }
    .mc2{padding:20px 25px;}

    .closeIt{color:$white !important;}
    .titleLine{margin-bottom:15px;@include center-block();}
    p.h3{font-size:1.2em;}
    p.h4{margin-bottom:10px;}
    p.h4 br, p.ctap{display:none;}
  }

  @include bp(oc){width:95%;
    .mc1{padding-top:30px;}
    p.h4{font-size:1.2em;font-weight:800;margin:5px 0 12px;}
    p.h4 br, br.maybe{display:block;}
  }

  @media (max-width:500px){br.maybe{display:none;}}
}