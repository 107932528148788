@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.pillarsRow{@include sec-default();text-align:center;padding:0px 50px 20px 0; margin-top:-60px;
  p{float:right;font-size:25px;line-height: 1.3em;font-weight:400;
    span{font-weight:700;line-height: inherit;}
  }

  @include bp(tb){
    p{font-size: 21px;}
  }
}
.dtWrap.about .pillarsRow{display:none;}


.detailTabs{@include sec-default(); padding:0px 0 60px; 
  p.headline{@include sec-default();text-align:center;font-size:30px; color:$red; font-weight:800;}

  .tabNav{@include sec-default(); margin-left:0; text-align: center; padding-bottom:15px;
    &::before{width:80%;left:10%;}
    li{padding:27px 0 0; display:inline-block; margin:0 2%;position:relative;z-index: 0; 
      &::before{@include before(); background-size:25px; background-position: top center; background-repeat:no-repeat;}
      span {position: relative; z-index:0;}
      span::before{@include after();height:2px; display: none;}
      &, span{line-height:1.3em;}
    }
    button{font-size: 1.05rem;padding:0;font-weight:400;}
    button:hover{opacity: .7;}
    button span{font-size: .97rem;}
    .clearfix{display: none;}
  }
  
  .tabContent{width:100%; float:left; margin-top:20px;}
  .tcRow{@include sec-default();margin-bottom:20px;text-align:center;
    p{width:80%; @include center-block();}
  }

  .large,.small,.eq{display: inline-block;}
  .eq{width:48%;}
  .large{width:60%;}
  .small{width:37%;}
  .left{float:left;}
  .right{float:right;margin-right: -1%;}
  
  p.subject{margin-bottom:0px;}
  p.subject span{color:$black;font-style: italic;}
  p.pillars{font-weight:700;font-size:19px;line-height: 1.3em;
    span{line-height: inherit;}//text-transform: uppercase; letter-spacing: 1px;}
    &.AbHide{margin-bottom:30px;}
    &.BaseHide{display: none;margin-bottom:5px;text-shadow:0px 1px 2px rgba($black,.4);}
  }

  .naamta1{max-width:120px;float:left;padding:0 7px 3px 0;}
  // .naamta1.white{display: none;}
  .textLinkRed{color:$red2}
  .review{position: relative; padding-left:30px;&::before{@include before();width:2px; top:6px; background-color:$red;}}

  .dressup,.redBg{background-color:$red;padding:20px 20px 8px;margin-top:30px;
    p{color:$white;}
    .textLink,.textLinkRed{color:$white;}
    p.subject{margin-bottom:15px;}
  }
  .dressup{display:inline-block; text-align:center; padding:20px 15px 8px;margin-top:10px;
    &:last-child{margin-left:3%;}
  }

  .fleetTab .tcRow{max-width: 1000px;}
  .achieveTab .left{margin-top:40px;}
  .imgMobile{display:none;}
  ul.disc{padding-left:30px;
    p{margin-bottom: 0;margin-left:-30px;}
    li{margin-bottom:0px;}
  }

  &.about,&.bases{z-index: 1;
    p,a:not(.textLink),li,button{color:$white !important;}
    // .naamta1.color{display: none;}
    // .naamta1.white{display: block;}
    p.subject,.tabNav button, p b, p span{text-shadow: 0px 1px 1px rgba($red3,.7);}
    .textLink{color:$black;font-weight:bold;}
  }
  &.about{padding:100px 0 110px; 
    &::before,&::after{@include after();left:0;top:-130px;transform: skewY(-6deg);transform-origin: top right;}
    &::before{background-image: linear-gradient($redb, $redMd);opacity:.9;}
    &::after{@include sec-bgimage('./images/clouds.jpg');background-size:cover;background-position:bottom center;z-index:-2;}

    .achieveTab .right{margin-bottom:40px;}
    p.pillars.AbHide{display:none;}
    p.pillars.BaseHide{display:block;}
  }

  &.bases{margin-top:150px;padding:0 0 140px;
    &::before,&::after{@include after();left:0;top:-140px;transform: skewY(-6deg);transform-origin: top right;}
    &::before{background-image: linear-gradient($redb, $redMd);opacity:.9;}
    &::after{@include sec-bgimage('./images/clouds.jpg');background-size:cover;background-position:bottom center;z-index:-2;}
  }

  @media (min-width:960px){
    .marcTab{
      .eq.left{margin-left:-2%;}
      .eq.right{width:53%; margin-right:-2%;}
    }
  }
  @media (min-width:1700px){padding-top:90px;
    &.bases{padding-top:20px;}
  }

  @media (max-width:1650px) {&.about{padding-top:50px;}}

  @include bp(tx){padding-top:20px; }

  @include bp(tw){padding-top:0px;
    &.about{padding-bottom:160px;}
    &.about::before,&.about::after{top:-100px;transform: skewY(-8deg);}

    .right{margin-right:0;}
    .small{width:38%;}
    .tcRow p{width:90%;}
 
    .achieveTab .left{margin-top:0px;}
  }

  @include bp(tb){
    &.bases{padding:0 0 140px;margin-top:0;
      &::before,&::after{top:-115px;}
    }
  }

  @include bp(br){
    &.about{padding:20px 0 160px;}
    &.about::before,&.about::after{top:-170px;}
  }

  @media (max-width:959px){
    .tcWrap{max-width:700px;}
    .tcRow{text-align: left;margin-bottom: 15px;;}
    .tcRow .medDef,.tcRow p{width:100%;}
    .large,.small,.eq{@include sec-default();}
    .left{margin-bottom:15px;}

    .tnInside{width:90%;}
    .tabNav{
      &::before{width:95%;left:2.5%;}
      button{font-size:1rem;}
    }

    .imgFw{display:none;}
    .imgMobile{display:block;}

    &.rapid{padding-bottom:0px;}
  }

  @include bp(md){
    &.about::before,&.about::after{height:102%;}
    &.bases{padding-bottom:90px;margin-bottom:30px;
      &::before,&::after{top:-90px;}
      .achieveTab .imgMobile{padding-bottom:30px;}
    }
  }

  @include bp(og){padding-bottom:0px;
    .tabNav{padding-bottom:0;
      li{padding:18px 0 0; margin:0 3% 15px;line-height:1.1em;
        &::before{background-size:20px;}
      }
      .clearfix{display: block;}
    }
    .tabContent{margin-top:10px;}
    p.pillars{font-size:17px;}

    &.bases{padding-bottom:55px;margin-bottom:40px;
      &::before,&::after{top:-70px;}
    }
  }

  @include bp(oc) {
    &.about{padding-bottom: 100px;}
    &.about::before,&.about::after{height:105%;}
    p.ilp{@include sec-default();}
  }
}
