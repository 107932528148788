@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header1{@include sec-default();text-align:center; position: relative;z-index: 1;
  .topbar{@include sec-default();background-color:$f7;margin-bottom:15px;padding:10px 0 9px;
    &,a,button{line-height:1.1em;}
    &,a{font-weight:400;font-size:14px}
    button{font-weight:bold;color:$red2;font-size:15.5px;}
    // .marcLinkSm:hover{color:$white !important;}

    .tbcPhone,.tbcEmail,.tbcBtn{display:inline-block;vertical-align:top;width:210px;}
    .tbcPhone{float:left;text-align:left;}
    .tbcEmail{float:right;text-align:right;}

    @include bp(tb){margin-bottom:5px;}
    @include bp(br){
      .inside{width:95%;}
      button{font-size:15px;}
    }
    @include bp(og){padding:9px 0 5px;
      .tbcBtn{@include sec-default();text-align:center;}//margin-bottom:5px;}
      .tbPhone,.tbEmail{width:auto;float:none;margin:5px;text-align:center;}
      button{font-size:14px;}
    }
  }

  .navMm{@include sec-default(); background-color: transparent;position: static;padding: 10px 0;}
  .logoBlk, .navBarCustom, .navItem{display: inline-block;}
  .logoBlk{padding:0; margin:20px 0px 18px;width:30%;float:left;max-width:330px;}
  .navBarCustom{margin:45px auto 0; width:70%; float:right; text-align:right;}

  .hdCol{display: inline-block;margin-top:30px;}
  .colLogo{margin:5px 4% 0 3%;max-width:330px;}
  .col1{float:left;margin-left:-3%;}//margin-right:3%;}
  .col2{float:right;margin-right:-4%;}

 
  .navItem {margin:0 15px;//position: relative;z-index: 1;
    &, a{font-family:$man; color:$black;letter-spacing:1px;}
    .dropLi, .dropLi a{font-family: $mont;}
    a:hover, &:hover > a {color: lighten($red, 15%) !important; opacity: 1;}
    a{text-transform: none; font-size:1.1rem;padding:0;margin:0; min-height:auto;}
    .parentIcon svg{height:18px;}//padding-top:3px;}
  }
  
  .dropItem{position: absolute; width:100%;left:0; overflow: hidden;}
  .menuDrop{text-align: left;width:850px; width:100%;
    background-color: transparent; padding:40px 0 0; overflow: hidden; 
    &::before{@include before();transform: skewY(-4deg);transform-origin: top right;background-color: $black;}

    .mdWrap{@include sec-default();padding:75px 0px;}
    .inner{width:700px; @include center-block(); display:flex; align-items: center; justify-content: center;}
    .ndaContent{width:60%; float:left; width:57%;}
    ul{float: right; width:42%;
      border-left:1px solid rgba($white,.2);padding-left:20px;}
    &,p{color:$white;}
    .tagline{font-size:25px; margin:15px 0 0; font-weight:bold;text-transform: uppercase; text-align: center;}

    .dropLi{@include sec-default(); margin: 10px 0; padding:0;line-height:1.2em;
      &,a{font-size: 19px; color:$white; font-weight:600;line-height:1em;}
      span{font-size:15px;font-weight: normal;color:$white;font-style: italic;}
    }
  }

  .mobNav{display: none; float:right; vertical-align: top;margin-top:5px;}
  .mobileNavBtn{@include sec-default();width:22px;
    span{@include sec-default(); height:2px; margin:2px 0; background-color: $black;}
    p,.drawerText{display: none;}
  }

  @media (max-width:1400px){
    .col1,.col2{margin-top:25px;}
    .colLogo{margin-right:3%;}
  }
  @include bp(tw) {padding-bottom:40px;
    .medDef{max-width:720px;}
    .colLogo{max-width:300px;@include center-block();margin-top:0;}
    .col1,.col2{margin:10px 0 0;float:none;}
    .navItem a, .navItem button{font-size:1.05rem;font-weight: 400;}

    .menuDrop .mdWrap{padding:30px 0px 35px;}
  }
  @include bp(tb) {padding-bottom:30px;
    .navItem a, .navItem button{font-size:1rem;}
  }

  @include bp(br) {padding-bottom:0;
    .medDef{max-width:100%;}
    .col1,.col2{display: none;}
    .colLogo{max-width:220px;float:left;margin:5px 0;}
    .mobNav{display: inline-block;}
    .hdWrap{width:80%;}
  }

  @include bp(md) {
    .colLogo{margin:5px 0 10px;}
}

  @include bp(oc) {
    .colLogo{margin:5px 0 25px;}
    .hdWrap{width:90%;}
    .mobNav{margin-top:5px;}
  }
}

// .cNavDrawer{background-color:$red;}
.SideNavList{padding:40px 30px 0; width:300px;
  .SideNav{margin-top:10%;}
  .closeIcon {color: $white; position: absolute; right:10px; top:15px; cursor: pointer; font-weight: normal;
    svg{width:30px; height:30px;}
  }

  .navItemCat{@include sec-default();margin:10px 0 0;font-weight:bold;text-decoration:underline;font-size:16px;text-transform:uppercase;color:$white;}
  .navItem{@include sec-default();margin:0 0 22px;line-height:1em;
    a, button{font-weight:400; color:$white; text-transform:none; font-size:19px;
      line-height:1em;
      &:hover{opacity:.8;}
    }
    button{padding:0;}
    span{font-size:15px;font-weight:normal;color:$white;font-style: italic;text-transform: none;}
  }
}
