$fa: #fafafa;
$fa1: darken($fa, 1%);
$fa2: darken($fa, 2%);
$f0: #f0f0f0;
$f2: #f2f2f2;
$f7: #f7f7f7;
$f9: #f9f9f9;
$df: #dfdfdf;
$white: #fff;
$black: #000;
$red: #ed1e24;
$redb: darken($red, 6%);
$red2: darken($red, 12%);
$redMd: darken($red, 20%);
$red3: darken($red, 25%);
$redLt:#f5c4c5;
$blue:#1e87f0;
// $blue:#1e24ed;
$gray:#999999;
$grayDk:#6b6b6b;

$navy1:#001428;
$navy2:#000F1E;

$borderB: 1px solid rgba($black,.1);
$borderW: 1px solid rgba($white,.1);
$borderR: 1px solid rgba($red,.1);

$man: 'Manrope', sans-serif;
$mont:'Montserrat', sans-serif;
$boldFont:$mont;
$body: $mont;
$semi: 500;
$light: 300;