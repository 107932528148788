@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact{@include sec-default();
  .ContactBody{@include sec-default();padding:20px 0 120px;}
  .linkRow{@include sec-default();text-align: center;margin-bottom:40px;}

  .formImg,.ilForm{display: inline-block;}
  .formImg{float:left;width:44%;}
  .ilForm{width:60%;margin-left:-4%;margin-top:5%;
    background-color: $fa; padding:30px; box-shadow: 0px 10px 25px rgba($black,.1);
  }

  @include bp(tw) {
    .ilForm{margin-top:3%;}
  }

  @include bp(tb) {
    .ContactBody{padding:10px 0 80px;}
    .linkRow{margin-bottom:25px;}
    .formImg{width:40%;}
    .ilForm{width:66%;margin-left:-6%;margin-top:5%;}
  }

  @include bp(br) {
    .formImg{width:44%;}
    .ilForm{width:62%;}
  }

  @include bp(og) {
    .ContactBody{padding:10px 0 50px;}
    .linkRow{margin-bottom:10px;}
    .formImg{display:none;}
    .ilForm{@include sec-default(); margin:0; position: relative; z-index: 0; padding:20px 30px;
      @include sec-bgimage("./images/uniformMobile.jpg");
      &::before{@include before(); background-color:rgba($fa,.96);}
    
    }
  }
}