@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.EmploymentPage{@include sec-default();
  .PageIntro{@include sec-default(); margin:20px 0 100px;
    p{font-size:21px;}
    p span{font-weight:600;}

    .btnRow{@include sec-default();margin-top:30px;}
    .marcLink{margin:5px 10px;}
  
    @include bp(tb){margin:10px 0 80px;}
    @include bp(br){margin:5px 0 60px;
      p{font-size:19px;}
    }
    @include bp(og){p{font-size:18px;}}
    @include bp(oc){margin-bottom:45px;
    }

    @media (max-width:639px){margin-bottom:25px;
      .btnRow{width:95%;max-width:300px;@include center-block();margin-top:10px;}
      .marcLink{@include sec-default();font-size:14px;margin:7px 0;}
    }
  }
}

.StackLayout.Staff{padding-top: 0;
  .scStory{z-index:1;}
  .scStory .storyImage{width:49%;}
  .scStory p.bold span{font-weight:normal;}
  
  @media (min-width:1600px){.scStory .storyImage{width:45%;}}
  @media (min-width:1920px){.scStory .storyImage{max-width: 800px;right:auto;left:49%;}}
  
  @media (max-width:1315px){.scStory .storyImage{width:52%;}}
  @include bp(tw){.scStory .storyImage{width:50%;}}  
  @include bp(br){
    .scStory .storyImage{width:80%;max-width:600px;margin-top:10px;}
    .scStory p.bold span{display:none;}
  }
  @include bp(og){p.h4{font-size:1.2rem;margin-bottom:20px;}}
  @include bp(oc){
    .scStory{padding-bottom:10px; 
      .storyText{padding-left:20px;}
      .storyImage{width:95%;}
    }
    p.h4{font-size:1.1rem;margin-bottom:15px;}
  }
  @include bp(sm){
    .scStory {
      .storyText{padding-left:0px;}
      .textbox::before{left:-20px;}
    }
  }
}

.employTabs{@include sec-default();position:relative;z-index:0;
  &::before{@include before();background-image:linear-gradient($red, darken($red, 15%));transform-origin:top right;
    height:260px;top:-215px;transform:skewY(-9deg); // NARROW
  }

  .tabNav{@include sec-default();margin:20px 0 160px;padding-bottom:5px;border:0;text-align:center;
    &::before{opacity: .6;width:60%;left:20%;display: none;}
    li{padding:27px 0 0;display:inline-block;position:relative;z-index:0;margin:0 30px;
      &::before{@include before(); background-size:25px; background-position: top center; background-repeat:no-repeat;}
      &::after{@include after();height:2px;background-color:$white;opacity:.3;left:0;}
    }
    button{font-size:25px;font-weight: bold;color:$white !important;}
    button:hover{opacity: .7;}
  }

  .tabContentWrap{@include sec-default();} 
  li.medTab,li.pilotTab{margin-top:0;margin-bottom:0 !important;}

  .size1wrap,.size0wrap{width:95%;max-width:950px;@include center-block();} // margin-left:10%;}
  .minReq,.training{padding-bottom:60px;}
  .minReq{text-align: center;}

  .item{display: inline-block;}
  .minReq .item{width:50%;}
  .item1,.item2{width:47%;vertical-align: top;}
  .item1{margin:0 0 0 2%;}
  .item2{float:right;}
  .itema,.itemb{@include sec-default();}
  .training .item{
    p{position: relative;}
    p::before{@include before();width:5px;height:5px;background-color: $red;border-radius:50%;}
  }
  .itema p:first-child::before{display: none;}

 
  p.h4{font-size:1.1rem;font-weight:700;margin-bottom:20px;color:$red; text-align: center;line-height:1.3em;}
  p.subject{margin-bottom:2px;color:$blue;font-size:1rem;}
  p.mobInstr{display: none;}

  .medTab {
    .minReq p{line-height: 1.3em;}
    .training .item p{padding-left:5px;line-height:1.4em;margin-bottom:20px;}
    .training .item p::before{top:50%;margin-top:-2.5px;left:-12px;}
  }
  .pilotTab {
    .minReq .item{width:36%;}
    .minReq p{margin-bottom:8px;}
    .training{text-align: center;}
    .training p.h4{margin-bottom:10px;}
    .training .item p::before{left:50%;margin-left:-2.5px;top:-10px;margin-top:0;}
  }

  .logoRow{display:inline-block;}
  .logo1{width:145px;}
  .logo2{width:285px; float:left;margin-top:21px;}
  // currently no logo2


  @media (min-width:1700px){
    &::before{top:-195px;}
    .tabNav{margin-top:55px;}
  }
  @media (min-width:1900px){
    &::before{top:-225px;}
    .tabNav{margin-bottom:130px;}
  }
  @media (min-width:1925px){.tabNav{margin-bottom:160px;}}
  @media (min-width:2200px){
    &::before{top:-255px;height:310px;}
    .tabNav{margin-top:80px;}
  }  
  @include bp(tl){
    &::before{height:240px;}
    .tabNav{margin-top:-10px;}
  }
  @include bp(tw){
    &::before{height:145px;top:-125px}
    .tabNav{margin:0px 0 110px;}
  }
  @include bp(tb){&::before{top:-115px}}
  @include bp(br){
    &::before{height:350px;top:-270px;}
    .tabNav{margin:0px 0 100px;}
    .tabNav button{font-size:21px;}
    .tabNav li:first-child{margin-left: 0}
    
    p.mobInstr{@include sec-default(); color:$white;text-align: center;padding:0;font-weight:500;font-size:17px;}
    .minReq,.training{padding-bottom:40px;}
    .medTab .training .item p{padding-left:0;
      &::before{left:50%;margin-left:-2.5px;top:-13px;margin-top:0;}
    }
    
    .item,.minReq .item, .pilotTab .minReq .item{width:90%;@include center-block();}
    .itema,.itemb,.item1,.item2{max-width:570px;text-align:center;}
    .item1 p:first-child::before{display: none;}

    .pilotTab {
      .minReq p{margin-bottom:5px;}
      br{display: none;}
    }
    .medTab .minReq p.h4, .training p.h4{margin-bottom: 10px;}

    p.h4{font-size: 1.05rem;}
    .logo1, .logo2{float:none;vertical-align:top;padding:5px;}
    .logo1{width:135px;}
    .logo2{width:275px;margin-top:15px;margin-left:25px;}
  }
  @include bp(md){&::before{height:360px;}}
  @include bp(og){.tabNav button{font-size:19px;}}
  @include bp(oc){
    &::before{height:295px;top:-180px}
    .tabNav{margin:0px 0 90px;}
    .tabNav li{margin:0 20px 0 0;}
    p.mobInstr{font-size:16px;max-width:290px;@include center-block();line-height: 1.3em;}
    p.mobInstr span{line-height:inherit;}
    .minReq,.training{padding-bottom:25px;}
    .logo1, .logo2{display:block;margin:10px auto;}
    .logo2{margin-top:0;padding-top:0;}
  }
  @include bp(sm){&::before{height:265px;top:-140px}}

}