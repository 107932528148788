@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.LeadershipPage{
  p{line-height:1.4em;}
  p.name{font-weight:bold;margin:0;}
  p.title{font-size:15px;font-style:italic;}

  .plLeads{@include sec-default();
    .top,.two{@include sec-default();margin-bottom:40px;}
    .plItem{display:inline-block;vertical-align:top;margin:0 2% 50px;}
    .top .plItem{margin-bottom:20px;}
    .group{width:100%;display:flex;align-items:center;justify-content:center;}
    p.name{font-size:19px;}
  }

  .leadRow{@include sec-default();position:relative;z-index:4;padding:0 0 100px;}
  .image,.itemGroup{display:inline-block;}
  .image{width:38%;float:left;margin:-130px 0 0 -5%;}
  .itemGroup{width:69%;float:right;margin-right:-6%;}
  .item{@include sec-default();margin-bottom:15px;}

  .item p.name{font-size:18px;line-height:1.3em;}
  span.title {font-size:15px;font-style:italic;font-weight:normal;margin-bottom:0;}
  br.noshow{display:none;}


  @media (max-width:1250px){
    .medDef.lrmed{width:97%;max-width:950px;}
    .leadRow{margin-top:-30px;padding-bottom:50px;}
    .image{margin-left:0;width:42%;margin-top:-110px;}
    .itemGroup{margin-right:0;width:54%;}
    br.noshow{display:block;}
  }

  @include bp(br){
    .medDef.lrmed{width:95%;}
    .plLeads {margin:20px 0 60px;
      p.name{font-size:18px;}
    }
    .itemGroup{width:67%;margin-top:-40px;margin-right:-2%;}
    .image{margin-top:-120px;width:32%;}
    .item p.name{font-size:17px;}
    p.title,span.title{font-size:14px;}
  }

  @include bp(og){
    .plLeads{margin:0px 0 30px;
      .top{margin-bottom:10px;}
      .group{display:block;}
      .plItem{margin-bottom:30px;}
    }

    .medDef.lrmed{width:90%;}
    .leadRow{margin-top:-20px;}
    .image{display:none;}
    .itemGroup{@include sec-default();margin-top:0;text-align: center;}
  }
  @media (max-width:500px){
    .plLeads .plItem{@include sec-default();}
    span.title{@include sec-default();}
    span.title span{display:none;}
  }
}