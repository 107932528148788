@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.fbWrap{@include sec-default();text-align:center;padding-top:50px;
  p{font-size:19px;font-weight:500;margin-bottom:40px;}
  button{padding:11.5px 20px;}
  button:hover{color:$white !important}
  li{display:inline-block;margin:10px 2%;}

  @include bp(tb){button{padding:12px 20px;}}
  @include bp(nn){padding:0 0 60px;
    .medDef{width:95%;}
    li{margin:20px 1%;}
  }

  @include bp(og){padding:10px 0 50px;
    .medDef{width:95%;max-width:500px;}
    p{font-size:18px;margin-bottom:20px;}
    li{margin:15px 1%;}
  }
}