@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.cFormWrap {@include sec-default();
  p.successSub{color:$blue;}
  p.errorSub{color:$red;}
  h3{font-weight:bold;text-transform:uppercase;letter-spacing:1px;font-size:18px;position:relative;z-index:0;padding-bottom:6px;margin-bottom:30px;
    &::before{@include abs();width:50px;height:3px;bottom:0;left:0;background-color:rgba($red,1);}
  }

  .customer,.property,label, .cfield {@include sec-default();}

  .formGroup{@include sec-default();margin-bottom:17px;}
  .formGroup.final{margin-bottom:0px;}
  .btnRow{@include sec-default();}
  
  label{font-size:15px;font-weight:500;}
  input, textarea, select{border:none;border-bottom:2px solid rgba($blue,.25);padding:2px 2px 2px 5px;font-weight:500;margin-bottom:20px;font-size:15px;font-family:$body;font-weight:normal;}
  input, select {min-height:35px;}
  textarea {min-height:125px;padding-top:5px;}
  select{width:100%;max-width:300px;}
  .marcLink{font-size:16px;min-height: auto;padding: 10px;width:135px;float:right;margin-top:20px;}

  @include bp(br) {
    h3{font-size:17px;}
    .formGroup:not(.fgdets){@include sec-default();margin:0 0 10px;} 
  }

}