@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.EmployeeLinks{@include sec-default();text-align:center;padding-bottom:60px;
  .insideNrw{max-width:700px;}
  .group{@include sec-default();}
  .group.two{margin:30px 0 50px;}
  

  p.cat{font-weight:bold;text-transform: uppercase;text-decoration: underline;margin-bottom:5px;}
  // p.cat.last{@include sec-default();margin:50px 0 0;}
  p span{font-size:16px;}

  .img2 img{background-color:$black;}
  // img{float:left;}
  img:hover{opacity: .7;}

}