@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.LocationsPage{@include sec-default();padding-bottom:20px;}
.clearfixBtn{margin-top:10px;}
.bioModal .bmBody{width:80% !important;padding:30px;}
p.bioName{font-weight:bold;border-bottom:1px solid rgba($black,.1);}
@include bp(md){.bioModal .bmBody{width:90% !important;}}
@include bp(sm){.bioModal .bmBody{width:99% !important; padding:20px 15px;}}

.bi2{display: none;}

.Banner{@include sec-default();padding:20px 0 15px;text-align:center; background-color:$red; 
  // padding: 40px 0 50px;// baseIntro 2/3
  p{color:$white; font-size:30px; font-weight:bold;font-family: $boldFont;margin:5px 0 0;}
}