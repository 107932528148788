// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.HomePage{@include sec-default();
  .laxQuote{position: absolute; width:550px; bottom:5px;left:20px; text-align: left;}
  .laxQuote p{font-size:20px; font-weight:500;}
  // .cXamtsLogo{max-width:190px;}
  .namLogo{max-width:120px;margin-bottom:10px;}
  @include bp(br){.namLogo{max-width:100px;}}
  @include bp(ph){.namLogo{max-width:85px;}}

  p.style{font-size:17px; line-height: 1em; font-weight: bold; color:$red; margin:0 0 5px; text-transform: uppercase; letter-spacing: 2px;}
  p.secTitle{font-size: 1.75rem;font-weight:bold; line-height: 1.3em;
    b{font-weight: 800;}
  }

  p.rotator{@include sec-default(); line-height:1.2em;font-size:36px;position:relative;color:$red;font-weight:bold; margin-bottom:0;}
  .rwWords{display: inline;
    span{color:$red2;font-weight:800;position: absolute;opacity:0;overflow:hidden;width:100%;animation: rotateWordsSecond 12s linear infinite 0s;margin-bottom:0; z-index:3;}
    span.two {animation-delay: 3s;}
    span.three {animation-delay: 6s;}
    span.four {animation-delay: 9s;}

    @include bp(oc){
      .rwWords{@include sec-default(); text-align:center;}
      span{opacity:1;animation:none;position:relative;width:auto;}
    }
  }
  @keyframes rotateWordsSecond {
    0% {opacity: 1; animation-timing-function: ease-in; width: 0px;}
    10% {opacity: 1; width: 100%;}
    20% {opacity: 1; width: 100%;}
    27% {opacity: 0; width: 100%;}
    100% {opacity: 0;}
  }

  .HomeSec{@include sec-default();padding:70px 0; position: relative;
    .left, .right{display: inline-block;}
    .left{float: left;}
    .right{float: right;}
    .lgText p{font-size:20px;}

    .equal{
      .left,.right{width:49%;}
    }

    .leftLg{
      .left{width:60%;}
      .right{width:37%;}
    }
    .leftSm{
      .left{width:37%;}
      .right{width:60%;}
    }
    .bothSm{
      .left{width:40%;}
      .right{width:40%;}
    }

    .altSize{
      .left{width:55%;margin-left:2%;}
      .right{width:42%;width:38%;margin-right:2%;}
    }

    .inline{position:relative;
      p.h3{@include sec-default(); line-height:1.2em;font-size:1.8em;position:relative;
        color:$red;font-weight:bold; margin-bottom:0;
      }

      .left{width:23%;margin-left: -2%;}
      .right{width:65%;margin-left:1.2%;float:none;padding-left:3px;}
      .links{width:14%;position:absolute; right:-2%; top:18px;
        a{box-shadow:0px 10px 20px rgba($black,.15);@include sec-default();}
        a:first-child{margin-bottom:20px;}
      }
    }


    @include bp(tw) {
      .inline{width:95%;max-width:950px;
        .left{width:27%;margin-left:0;}
        .right,.links{width:71%;float:right;}
        .right{padding-left:0;margin-left:0;}
        .links{position:relative;right:auto;top:auto;
          a{display:inline-block;width:140px;margin:10px;}
          a:first-child{margin-left:0;}
        }
      }
    }

    @media (max-width:959px){padding:30px 0;
      .inline{width:95%;max-width:700px;text-align:center;
        .left,.right,.links{width:100%;}
        .links a{float:none;}
      }
    }

    @include bp(og){padding:15px 0;
      .inline{width:92%;max-width:100%;}
    }
  }

  .sec0{
    p:not(.rotator){font-size:21px;}
    p.rotator{margin-top:15px;}

    &::after{@include after();left: 0;background-image: linear-gradient($red, $red3);transform-origin: top left;transform: skewY(-4deg);}
    .skewWrap{@include sec-default();margin-bottom:75px;}
  
    p{color:$white;text-shadow: 1px 1px rgba($red3,.6);}
    .rwWords span{color:$white;text-shadow: 1px 2px rgba($red3,.6);}
    .right{padding-right:10px;}

    @media (min-width:1201px){.inline .right{padding:0 30px 0 25px;width:60%;margin-left:2.5%;}}
    @include bp(tw){
      .skewWrap{margin-bottom:50px;}
      .right{padding-right:0px;}
    }
    @include bp(tb){.skewWrap{margin-bottom:35px;}}
    
    @media (max-width:959px){
      .rwWords{text-indent:9px;text-align: left;}
      p.rotator{width:50%;@include center-block();text-align:left;font-size:30px;margin-top:0;
        br{display: none;}
      }
      p:not(.rotator){font-size:20px;}
    }

    @include bp(og){
      .skewWrap{margin-bottom:35px;}
      p.rotator{width:50%;font-size:25px;}
      p:not(.rotator){font-size:18px;}
    }

    @include bp(oc){padding-top:20px;
      p.rotator{font-size:20px;width:100%;text-align: center;margin-bottom:15px;line-height:1em;
        br{display: block;}
      }
      .rwWords span{text-indent:0;line-height:1em;}
    }
  }

  .HomeBeneBoxes{@include sec-default();text-align: center;position: relative;z-index: 0;padding:30px 0 80px;//padding:80px 0 120px; 
    &::before{@include before();@include sec-bgimage('./images/planeBg.png');background-size:180px;background-position:top center;opacity: .35;top:-15px;}//top:25px; 

    .hbBody{@include sec-default();}
    .titleBox{@include sec-default();text-align:center;margin-bottom:60px;
      p b{font-weight:500;}
    }

    .left{float:left;}
    .right{float:right;}
    .item{display:inline-block; position: relative;z-index:0;padding-top:25px; vertical-align: top;margin-bottom:20px;
      &::before{@include before();@include sec-bgimage('./images/MARCsymbol.png');background-position: top center;
        height:30px;width:30px;left:50%;margin-left:-25px;
      }
    }

    .row2{width:48%;}
    .row2.right{margin-top:0;}
    .row3{width:30%;margin-top:60px;}
    .row3.plus{width:46%;}
    .row3.left{margin-left:-5%;}
    .row3.right{margin-right:-5%;}

    p.h4{font-size: 1.2rem;font-weight:bold; line-height: 1.1em;color:$red;margin-bottom:20px;}
    p.hi{margin-top:5px;}
    p.hi,p.hi span{font-size:20px;line-height: 1em; font-weight:600;margin-bottom:5px;}
    p.hi span{font-weight: 800;}
    p.secTitle{margin-bottom:10px;color:$red;}
    p.subTitle{font-weight:700;letter-spacing:5px;text-transform: uppercase;font-size:17px;margin-bottom:0;}
    p span.red{font-weight:700;color:$red2;}
    .textLink{color:$blue;}

    .accordTitle, .accordContent{margin:0;width:100%;float:left;}
    .clearfix.thHide{display:none;}

    @media (min-width:1025px){.item:hover::before{transition: transform .5s ease;transform: scale(1.2);}}
   
    @media (min-width:960px){
      .accordTitle{position: relative;z-index: 0;text-align:center;
        &::before{display:none;}
        &::after{@include after();background-color:transparent;}
        &:hover{cursor: default;}
      }
      .accordContent{display:block !important;}
      .clearfix.thHide{display:block;}
    }

    @include bp(tw){padding:50px 0 120px;
      &::before{top:0;}
      .row3.plus{@include sec-default();margin-top:10px;
        p{max-width:755px;@include center-block();}
      }
      .row3.left,.row3.right{width:48%;margin:15px 0 0;}
    }

    @media (max-width:959px){padding:20px 0 50px;
      .titleBox{margin-bottom:30px;}
      p.secTitle{font-size:25px;}
      .row2.left,.row2.right,.row3.left,.row3.right,.row3.plus{@include sec-default();margin:10px 0 15px;}
      .row3.plus p{max-width:100%;}
    }

    @include bp(og){
      p.subTitle{letter-spacing:1px;font-size:16px;}
    }
  } 

  .hbLax{@include sec-default();
    p{font-weight:bold;}
    p.line1{font-size:25px;line-height: 1.4em;}
    p.line2{font-size:30px;}
    img{width:700px;}
  }


  .quoteSlides{@include sec-default();}
  .homeNews{@include sec-default();padding:90px 0 100px;
    .newsItem{display: inline-block;position:relative;text-align:center;width:32%;
      p,a.niLink{color:$white;text-shadow:0px 1px 2px rgba($black,.8);}
      p{font-weight:600;font-size:19px;line-height:1.4em;}
      a p{margin-bottom:5px;}
      a.niLink span{font-weight:500;font-style: italic;font-size:17px;}
      a.niLink:hover span,a.niLink:hover p{opacity: .7;@include ease(.5s)}

      // ************* FOR LONG TITLES ************* //
      p.longTitle{max-width:355px;@include center-block();}
    }

    img{position: relative;z-index:-1;}
    .textBox{position: absolute;z-index:0;padding:0 20px; width:100%; //width added after long titles
      &::before{@include before();height:150%;top:-30%;}
    }

    .item1{margin-top:140px;float:left;
      .textBox{top:45%;}
      .textBox::before{background: radial-gradient(closest-side, rgba($black,.6), rgba($black,.6), transparent);}
    }
    
    .item2{margin-top:70px;margin-left:2%;
      .textBox{top:10%;padding:0 30px;}
      .textBox::before{background: radial-gradient(closest-side, rgba($black,.3), rgba($black,.1), transparent);}
    }
    .item3{float: right;overflow: hidden;
      .textBox{bottom:6%;}
      .textBox::before{display: none;}
      &::after{@include after();height:250px;left: 0;background-image: linear-gradient($red, $red3);transform: skewY(-9deg);transform-origin: top right;}
    }
    
    @media (max-width:1500px){.insideAlt{width:90%;}}
    @include bp(tw){padding:40px 0 80px;.insideAlt{width:95%;}}
    @include bp(tb){padding-top:30px;
      .newsItem{width:33%;
        p{font-size:18px;}
        a.niLink span{font-size:16px;}
      }
      .textBox{padding:0 10px;}
      .marcLinkRev{padding:5px 25px;}
      
      .item2{margin-left:.5%;
        .textBox{top:7%;}
      }
      .item3{
        &::after{height:215px;}
        p{margin-bottom:5px;}
      }
    }

    @media (max-width:959px){padding:60px 0 60px;
      .insideAlt{max-width:750px;}
      .item1,.item2{width:48%;margin:0;max-height:400px;overflow:hidden;}
      .item2{float:right;
        .textBox{top:10%;}
      }
      .item3{@include sec-default();margin-top:30px;height:250px;
        @include sec-bgimage('./images/marc14Mob.jpg');background-size:60%;background-position:center right;
        &::after{height:100%;width:50%;left:-10%;transform: skewY(0deg);transform: skewX(15deg);z-index: 0;}
        
        img{display: none;}
        .textBox{bottom:18%;z-index: 1;width:40%;text-align: left;padding:0 0 0 30px;}
        p{line-height:1.5em}
      }

      // ************* FOR LONG TITLES ************* //
      .newsItem p.longTitle{max-width:275px;}
    }

    @include bp(md){
      .newsItem{
        p,a.niLink{text-shadow:0 1px 1px rgba($black,.8);}
        p{font-size:17px;}
      }
    }

    @include bp(og){padding:40px 0 40px;
      .insideAlt{width:90%;}
      .htBody{@include sec-default();@include sec-bgimage('./images/marc14Mob.jpg');position: relative;
        &::before{@include before();left:0;background-color:rgba($black,.8);z-index: 0;}
      }
      .item1,.item2{@include sec-default();border-bottom:4px solid $white;
        img{display: none;}
      }
      .item1,.item2,.item3{height:100%;margin:0;padding:30px 0;
        .textBox{width:90%;max-width:400px;position:relative;margin:auto;top:auto;bottom:auto;}
        .textBox::before,&::after{display: none;}
      }
      .item2{@include sec-default();}
      .item3{background-image:none;padding-bottom:40px;
        .textBox{max-width:450px;text-align:center;padding:0;}
      }

      // ************* FOR LONG TITLES ************* //
      .newsItem p.longTitle{max-width:300px;}
    }

    @include bp(oc){
      .htBody{background-size:cover;}
      .newsItem p{font-size:16px;}
      .newsItem a.niLink{font-size:15px;}
      .item1,.item2,.item3{
        .textBox{max-width:100%;}
      }

    }
  }  
}