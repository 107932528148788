@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.SkewBg{@include sec-default();position: relative;z-index: 0; overflow: hidden; text-align:center;margin-bottom:30px;padding:120px 0 40px;
  &::before{@include after();top: 0;right: 0;left: 0;background-image: linear-gradient($red, $red3);transform: skewY(-5deg);transform-origin: top right;}

  h1,h2,h3,h4,p{color:$white;}
  h1{font-size: 2.25rem;margin-bottom:5px;}
  h2, h4{letter-spacing: 10px; text-transform: uppercase; margin-bottom:0;font-size: 1.3rem;}
  h3{@include sec-default();font-size: 2rem;margin-bottom:5px;font-weight: bold;}
  h3,h4{line-height: 1.3em;}

  &.SkewHd{margin-top:-50px;}
  &.SkewStack, &.SkewStack2{
    h1,h3{font-size:56px;line-height: 1.1em;margin:20px 0;}
    h2,h4{position:absolute; color:$red3; opacity: .55; letter-spacing: 0;
      font-weight:800;font-family:$boldFont;font-size:70px;margin-top:15px;
      width:100%;z-index: -1; left:0;}
  }
  &.SkewStack2{
    h1,h3{font-size:60px;}
    h2,h4{opacity: .25;line-height: 1.1em;font-size:50px;margin-top:-15px;}
  }

  &.BaseStack{padding:15px 0 130px;overflow: visible;margin:-40px 0 0;
    &::before{top:-120px;}
    h3,h5,p{position:relative;z-index:2;}
    h4{position:absolute; color:$red3; opacity:.5;font-weight:800;font-size: 2.2rem;line-height:1.3em;
      letter-spacing:0px;z-index: 1;left:0;width:100%;top:-3px;
    }
    h3{font-size:2.6rem; font-weight:800;margin:0;}
    h5{@include sec-default();font-size:21px;color:$white;text-transform:uppercase;font-weight:500;letter-spacing:5px;}

    &.rapStack{padding-bottom:100px;
      h4{font-size:35px;top:0;opacity: .3;}
      h4 br{display: none;}
    }
  }

  &.rev{padding:35px 0 120px;
    &::before{transform: skewY(5deg);}
  }
  &.flip::before{transform-origin: top left;}
  &.color2::before{background-image: linear-gradient($red3, $red);}
  &.SkewBanner{margin-bottom:15px;}

  @media (min-width:1700px){padding-top:130px;
    &.rev{padding:40px 0 130px;}
    &.BaseStack::before{top:-130px;}
  }
  @media (min-width:1880px) {
    &.BaseStack{margin-top:-95px;padding:50px 0 100px;
      &::before{top:-100px;}
      h4{top:31px;}
    }
    &.BaseStack.rapStack{padding:60px 0 70px;
      h4{top:43px;}
    }
  }
  @media (min-width:2200px) {
    &.BaseStack{padding:70px 0 130px;
      &::before{top:-140px;}
      h4{top:51px;}
    }

    &.BaseStack.rapStack{padding:50px 0 105px;
      h4{top:25px;}
    }
  }
  
  @include bp(tw){padding-top:100px;
    &.rev{padding-bottom:100px;}
    &.BaseStack{margin-top:0px;}
    &.BaseStack::before{top:-100px;}
    &.BaseStack.rapStack::before{top:-110px;}
  }
  @include bp(tb){padding:90px 0 35px;
    h1{font-size: 2rem;}
    &.SkewHd{margin-top:-40px;}
    &.SkewStack{
      h1,h3{font-size:50px;margin:0px 0 6px;}
      h2,h4{font-size:60px;}
    }

    &.BaseStack{
      h3{font-size:2.4rem;}
      h4{font-size:2.1rem;}
    }
    &.BaseStack.rapStack::before{top:-100px;}
  }

  @include bp(br){padding:80px 0 30px;
    &.rev{padding:30px 0 90px;}
    h1,h3{font-size: 1.8rem;}
    h2,h4{font-size:1.1rem;}
    &.SkewStack{
      h1,h3{font-size:40px;}
      h2,h4{font-size:50px;}
    }

    &.SkewStack2{
      h1,h3{font-size:55px;}
      h2,h4{font-size:45px;}
    }

    &.BaseStack{overflow: hidden;padding:35px 0 110px;
      h3{font-size:35px;}
      h4, &.rapStack h4{font-size:30px;top:23px;}
    }
    &.BaseStack.rapStack{padding-bottom:90px;}
  }
  @include bp(md){padding-top:70px;
    &.rev{padding-bottom:80px;}
    h1,h3{font-size: 1.65rem;}
    h2,h4{font-size:1rem;}
  }

  @include bp(og) {margin-bottom:15px;
    &.SkewStack{
      h1,h3{font-size:35px;}
      h2,h4{font-size:45px;}
    }

    &.SkewStack2{
      h1,h3{font-size:45px;}
      h2,h4{font-size:35px;}
    }

    &.BaseStack{padding:35px 0 110px;
      h3{font-size:30px;}
      h4{font-size:27px;}
    }
    &.BaseStack.rapStack {
      h4{font-size:27px;}
      h5{font-size:18px;}
    }
  }

  @include bp(oc){padding:55px 0 25px;
    &.rev{padding:20px 0 55px;}
    h1,h3{font-size: 1.4rem;}
    h2, h4{letter-spacing: 5px;}

    &.SkewStack{
      h1,h3{font-size:25px;margin:0px;}
      h2,h4{font-size:35px;margin-top:8px;}
    }

    &.SkewStack2{padding:45px 0 10px;
      h1,h3{font-size:35px;}
      h2,h4{font-size:30px;}
    }

    &.BaseStack{padding:25px 0 70px;
      &::before{top:-60px;}
      h3{font-size:27px;}
      h4{font-size:24px;top:14px;}
    }
    &.BaseStack.rapStack {
      h4{font-size:24px;top:14px;}
      h5{font-size:16px;}
    }
  }

  @include bp(xx){
    &.SkewStack2{padding:20px 0 5px;
      h1,h3{font-size:25px;}
      h2,h4{font-size:23px;margin-top:-8px;}
    }
  }
}

.QuoteSkew{text-align:left;padding:70px 0 60px;margin-bottom:0;
  .textBox.custom{width:90%; max-width:840px;
    float:left;margin-left:4%;position: relative;
  }
  
  h3{font-size:28px;margin-bottom:20px;text-shadow:1px 1px $red3;}
  li{width:100%;}
  p{font-size:22px;font-weight:500;}
  p span{font-size:17px;font-style:italic;font-weight:300;}

  button{color:$white;padding-top:0;margin-top:0;opacity: .6;margin-right:-30px !important;margin-top:-40px;}
  button:hover,button:focus{color:$white !important;}
  button:hover{opacity: .4;}
  svg{height:18px;}
  
  @media (max-width:959px){padding:50px 0 40px;
    .textBox.custom{max-width:700px;}
    p{font-size:19px;}
    button{margin-right:-45px !important;margin-top:-30px;}
    svg{opacity:1;}
  }

  @include bp(md){padding:30px 0 20px;
    .textBox.custom{max-width:700px;}
    p{font-size:18px;}
    p span{font-size:16px;}
    button{margin-right:-35px !important;margin-top:-20px;}
  }

  @include bp(og){padding:20px 0 15px;
    .textBox.custom{max-width:100%;margin-left:3%;}
    button{margin-right:-6% !important;margin-top:-40px;}
    p{font-size:17px;}
  }

  // @include bp(oc){.textBox.custom{margin-left:2%;}}
  @include bp(sm){button{margin-right:-10% !important;}}
}

.homeTag{overflow: visible;margin-top:-130px;padding:80px 0 8px;
  &::before{top:-30px;}

  @media (min-width:1600px){padding-top:98px;}
  @media (min-width:1880px) {//margin-top:-170px;
    padding:60px 0 40px;
    &::before{top:-60px;}
    h3{margin:20px 0 0}
  }
  @media (min-width:2170px) {
    margin-top:-170px;
    padding:100px 0 30px;
    //padding:30px 0;
    // &::before{top:-60px;}
  }
  @include bp(tl){padding-top:70px;}
  @include bp(tw){padding:60px 0 15px;}
  @include bp(tb){padding:45px 0 15px;margin-top:-60px;
    h3{font-size:1.5rem;}
  }
  @include bp(md){padding:30px 0 25px;}
  @include bp(og){padding:30px 0 25px;margin-top:-60px;
    h3{font-size:21px;}
  }
}
.SkewBg.Leadership{margin-bottom:0;}

.ArticlePageTitle {
  h1{font-size:30px;max-width:520px;@include center-block();font-weight:bold;}
  a{color:$white;text-transform:uppercase;letter-spacing:2px;font-size:17px;}

  @include bp(br){
    h1{font-size:25px;max-width:475px;}
    a{font-size:15px;}
  }
  @include bp(og){h1{font-size:21px;max-width:420px;}}
  @include bp(oc){
    h1{font-size:19px;max-width:380px;}
    a{font-size:14px;}
  }
}