@import './variables.scss';
@import './mixins.scss';

h1, h2, h3, h4, h5, p, li, a, span {
  &.up{text-transform: uppercase;}
  &.cap{text-transform: capitalize;}
  &.bmargin0 {margin-bottom: 0;}
  &.bmargin5 {margin-bottom: 5px;}
  &.buff4{font-weight: 400;}
  &.buff{font-weight: 500;}
  b, &.bold{font-weight: 600;}
  strong, &.strong{font-weight: 700;}
  &.stronger, &.bolder{font-weight: 800;}
  &.em{font-style: italic;}
  &.red{color:$red;}
  &.redB{color:$red;font-weight: 500;}
  &.mantra{color:$red;font-weight:400;}
  &.review span.name{font-size:.75em;}
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .insideXl, .insideN, .insideNrw, .headWrap{@include center-block(); max-width:1700px;}
.insideXl{width:95%;}
.inside{width: 85%;}
.insideN{width: 90%;}
.insideAlt{width: 80%;}
.medDef{width:1080px;}
@media (max-width:1235px){.medDef{width:90%;}}
.med2Def{width: 940px;}
.med3Def{width: 850px;}
@include bp(br){.med2Def, .med3Def{width:90%;}}
@media screen and (min-width:823px){.insideNrw{width:820px;}}
@include bp(tn){.insideNrw{width:90%}}

.fullRow{@include sec-default();}
.centerBlock{ @include center-block();}
.bmargin0 {margin-bottom: 0;}
.bmargin05 {margin-bottom: 5px;}
.bmargin1 {margin-bottom: 10px;}
.tmargin0 {margin-top: 0;}
.tmargin2 {margin-top: 20px;}
.textCenter {text-align:center;}
.textLeft {text-align:left;}
.textRight {text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1 {@include sec-default();height:10px;}
.spacer2 {@include sec-default();height:20px;}
.spacer25 {@include sec-default();height:25px;}
.spacer3 {@include sec-default();height:30px;}

hr{border-color: rgba($black,.1);
	&.hlf {width: 50%;}
	&.less {width: 25%;}
}
.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {
  content: "";
  clear: both;
  display: table;
}
.clearfix.btn, .clearfixBtn {@include sec-default(); margin-top:20px;}
.clearfixBtn2 {@include sec-default(); margin-top:10px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:50px; position: relative; z-index: 0; margin:0px 0 20px;
  &::before{@include before(); width:1px; left:49.7%; background-color: rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
.clearBorderLeft::before{left:12%;}

.marcLink,.marcLinkBlack,.marcLinkRound,.marcLinkLax,.marcLinkRev, .marcLinkSm{@include link($red, $white); padding: 10px 20px;
  box-shadow: 0 10px 25px rgba($black,.15);
  @include bp(tb) {font-size:15px;}
}
.marcLinkSm{padding:5px 20px;font-size: 15px;}
.marcLinkRound{border-radius: 40px; width:200px;}
.marcLinkBlack{background-color:$black;
  &:hover{color:$white !important;}
}
.marcLinkRev{background-color:$white;color:$red;
  &:hover{color:$red !important;}
}
.marcLinkLax{background-color:transparent;border:2px solid $white;box-shadow:0px 10px 20px rgba($black,.4);
  &:hover{background-color:$white;color:$black;}
  // &:hover{color:$white !important;opacity: .7;}
}

.textLink, .textLinkRed,.textLinkOg{font-weight:600;color:$blue;
  &.und{text-decoration:underline;}
  &.red{color:$red;}
  &:hover{color:darken($blue,15%) !important;opacity:1;}
}
.textLinkRed{color:$red;}
@media (min-width:768px){.textLinkOg{font-weight:300;color:inherit;}}

.simpleHeader{@include sec-default(); text-align: center; padding: 10px 0 35px;}

.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width: 100%; height: auto; display:block;}
ul.disc li{list-style-type: disc;}

.HeadBorder{@include sec-default(); border-top:10px solid $red;}
.quote p, .quoteB p, .quoteR p{font-size:21px; font-weight:bold; color:$red;
  span{font-size:17px; font-weight: normal; color:$grayDk; font-style: italic;}
}
.quoteB p{color: $black;}

.StackLayout{@include sec-default(); position: relative;padding:80px 0 30px;
  .tabWrap{padding:0;}
  p.title{font-weight:bold; color:$red;font-size:1.15rem;}
  p.focus,p.subject,p.name{line-height: 1.3em;}
  p.subject{color:$red;font-size: 1.1rem; margin-bottom: 10px;font-weight:bold;}
  .dots p:not(.h4){margin-bottom:20px; padding-left:5px; position: relative; z-index: 0; line-height: 1.4em; 
    &::before{@include before(); width:5px; height:5px; border-radius: 50%; background-color:$red; top:50%; margin-top:-2.5px; left:-12px;}
  }

  .scRow{@include sec-default();position: relative; padding-bottom: 100px;
    // &:first-child{margin-top: 120px;}
  }

  .scStory{//padding-top:80px;
    .storyText,.storyImage{display: inline-block;}
    .storyText{position:relative;float:left; width:50%; margin-left:-7%; margin-top:6%;}
    .storyImage{position: absolute; right:5%; width:45%; width:43%;}

    .textbox{position: relative;}
    .textbox::before{@include before();width:2px; background-color:$red;left:-10%;}
    .gridImage.tall{position:absolute; width:32%; bottom:0;right:0; top:85%;z-index: 1;}
    .siMobile{display:none;}

    b{font-weight:500;}
  }

  .scBg{margin-bottom:60px;
    &::after{@include before();background-color:$f9;z-index: -2;}
  }
  .scAfter::after{@include after();left:0;height:50px;background-color:$f9;z-index: -2;}
  .scBefore::before{@include before();height:80px;background-color:$f9;z-index: -2;}

  .scGrid{
    .med3Def{margin-left: 12%;}
    .gridWrap{float:left; width:90%; max-width: 830px;}

    .item,.item3{display:inline-block; vertical-align: top; margin:15px auto; position: relative; z-index: 2;}
    .item{width:48%;
      &.item2{float:right;border-right:none;}
    }
    .borders .item, .borders .item3{border-right: 1px solid rgba(black,.1);padding-right:20px;}
    .item3{width:30%; margin:15px 1.5%;
      &.i3{float:right;border-right:none;}
    }

    .gridImage{position:absolute;right:0;width:30%;}
    .gridImage.tall{width:32%; top:-100px;}
  }

  .scTabs{padding:80px 0; margin-left: 0;
    .tabWrap{float:left;margin-left:12%;max-width:750px;}
    .tabNav,.tabContent{display:inline-block; vertical-align: top;}
    .tabNav{width:28%; float:left; margin-left:0;
      &::before{display: none;}

      li{@include sec-default();margin-bottom:10px;}
      li span {position: relative; z-index:0;
        &::before{@include after();height:2px;}
      }
    }
    .tabContent{width:72%; position: relative; z-index: 2;
      ul{@include sec-default();}
      span.red{color:$black;}
      span{font-weight:500;}
    }

    button.subject{color:$red;font-size: 1.05rem;font-weight:bold;padding:0;}
    button:hover{color:$red !important; opacity: .7;}
    li{padding:0;}
    p b{font-weight:500;}

    .btnRow{@include sec-default();margin:0px 0 40px;
      .marcLink,img{display: inline-block;}
    }
    img{width:220px;}
    .camts{float:right;margin-top:-20px;}
  }
  
  .scTextSolo{
    .medDef{float:left; margin-left: 12%;}
    p{font-size:1.25rem;
      span{font-weight:500;}
    }
    .textBox{position: relative; z-index: 0; padding-left:50px; max-width:770px;
      &::before{@include before();width:2px; background-color:$red;}
    }
  }

 
  @media (max-width:1500px){
    .scStory {
      .storyText{margin-top:3%;}
      .gridImage.tall{top:80%;}
    }
    .scGrid .med3Def{margin-left: 6%;}

    .scTextSolo{
      .medDef{@include center-block();}
      .textBox{padding-left:50px; max-width:100%; width:85%;@include center-block();}
    }
  }

  @include bp(tl){
    .scStory{
      .storyText{width:50%; margin-left:-5%;}
      .storyImage{width:48%; right:3%;}
    }
    .scGrid .med3Def{margin-left: 4%;}
    .scTabs .tabWrap{margin-left:8%;}
    .scBefore::before{height:50px;}

    // .scBg{padding:0px 0 40px;}
  }

  @media (max-width:1315px){
    .scRow{padding-bottom:80px;}
    .scStory{
      .storyText{width:50%; margin-left:-2%; margin-top: 0;}
      .storyImage{width:52%; right:0;}
      .textbox{width:90%;}
      .textbox::before{left:-5%}
    }

    .scGrid .med3Def{max-width:750px;}

    .scTabs{
      .tabWrap{margin-left:6%;}
      .tabNav{width:25%;}
    }
  }

  @include bp(tw){
    .scRow{padding-bottom:30px;}

    .scStory{
      .storyText{width:50%; margin-left:1%; margin-top:5%}
      .storyImage{width:50%;}
      .gridImage.tall{width:27%;top:67%;}
    }

    .scGrid .med3Def{margin-left: 4%; max-width:750px;}

    .scAfter::after{height:30px}
    // .scBg{margin-bottom: 40px; padding-bottom: 10px;}
    
    .scTextSolo{
      .textBox{padding-left:30px;}// padding-right:30px;}
      p{font-size:1.15rem;}
    }
  }

  @include bp(tb){
    .scRow{padding-bottom:30px;}

    .scStory{
      .storyText{width:50%; margin-left:3%; margin-top:0px;}
      .storyImage{width:47%;}
      .gridImage.tall{width:27%;top:50%;}
    }

    .scGrid {
      .med3Def{max-width: 84%;@include center-block();}
      // p:not(.subject){background-color: rgba($fa2,.99);}
    }

    .scTabs{padding:40px 0;
      .tabWrap{margin-left:7%;max-width: 700px;}
      .tabNav{width:23%;}
      .tabContent{width:75%;}
    }
    
    .scTextSolo p{font-size:1.05rem;}

    p.title{font-size:1.05rem;}
    p.subject,button.subject{font-size: 1rem;}
  }

  @include bp(br){padding:30px 0 10px;
    .scRow{padding-bottom:30px;}

    .scStory{
      .medDef{max-width: 740px;}
      .storyText{width:100%; margin:0px 0;display:block;padding-left:30px;}
      
      .gridImage.tall,.storyImage{position: relative;top:auto; bottom:auto; left:auto; right:auto; vertical-align: top; display: inline-block; width:auto;}
      .imgBar{width:100%;display: flex; align-items: center; justify-content: space-between}
      .storyImage{width:60%;}
      .gridImage.tall{width:35%;}

      .storyImage.fw{display:none;}
      .siMobile{display:block; width:70%;}

      .textbox{width:100%;}
      .textbox::before{left:-30px;}
    }

    .scGrid {
      .item,.item3{margin:10px auto;}
      .med3Def{max-width: 100%; width:90%;}

      // .borders .item3{border-right:none;padding-right:0;}
    }
   
    .scTabs{padding:25px 0;
      .tabWrap{@include center-block();width:90%;max-width:740px}
      .tabContent{width:77%;}
    }

    .scAfter::after{height:25%;}
    
    .scBg{margin-bottom: 30px;}
    
    .scTextSolo{
      .textBox{width:95%;}
      p{font-size:1.05rem;}
    }

    p.title{font-size:1rem;margin-bottom:5px;}
  }

  @include bp(og){
    .scRow{padding-bottom:20px;}

    .scStory .medDef{max-width: 100%;}

    .scGrid {
      .med3Def{max-width: 550px; width:90%;}
      .item,.item3{@include sec-default();margin:0px auto 10px;}
    }

    .scTabs{padding:20px 0;
      .tabWrap{width:90%;max-width:100%;}
      .tabNav,.tabContent{@include sec-default();}
      .tabNav{background-color:rgba($redLt,.2);padding:10px 0 0px;margin-bottom: 15px;}
      .tabNav, a.subject,button.subject{text-align: center;font-size: 17px;}
      .tabNav li{display: inline-block; width:auto; margin:0 2% 10px;float:none;}
      .tabContent ul{width:90%; @include center-block();}
    }
    
    .scTextSolo{
      .textBox{width:100%;}
      p{font-size:1.05rem;}
    }

    p.title{font-size:1rem;}
    p.subject{margin-bottom: 5px;}
  }
}
