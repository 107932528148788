@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ArticlePage{@include sec-default();padding-bottom:60px;
  .abWrap{@include center-block();width:90%;}
  &.solo .abWrap{max-width:1000px;}
  &.item1 h1{max-width:680px;}

  .ArticleBody{@include sec-default();padding-top:30px;position: relative;
    a{font-weight: 600; color:$blue;
      &:hover{color:darken($blue,15%) !important; opacity:1;}
    }
    ul{padding:0 30px;}
  }

  &.withImg{
    .abWrap{max-width:1200px;}
    .articleImage,.articleStory{display:inline-block;}
    .articleStory{max-width:50%;float:right;}
    .articleImage{max-width:47%;float:left;}
    .imgRel{display:none;}
  }

  @media (min-width:1201px){&.solo .ArticleBody{padding-top:15px;}}
  @include bp(tw){
    &.solo .abWrap{max-width:900px;}
    .ArticleBody{padding-top:10px;}
  }

  @include bp(br){padding-bottom:30px;
    .ArticleBody{padding-top:0;}
    &.withImg{
      .articleImage,.articleStory{@include sec-default();max-width:100%;}
      .articleImage{margin-bottom:30px;}
      .aiStick{display:none;}
      .imgRel{@include center-block();max-height:400px;}
    }
  }

  @include bp(og){
    &.item1 h1{max-width:540px;}
    .ArticleBody ul{padding:0 15px;}

  }

  @include bp(oc){padding-bottom:20px;
    &.item1 h1{max-width:450px;}
    .ArticleBody ul{padding:0;}
    &.withImg{
      .articleImage{margin-bottom:20px;}
      .imgRel{max-height:330px;}
    }
  }

  @include bp(sm){
    &.withImg .imgRel{max-height:300px;}
  }
}