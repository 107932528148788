@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.FleetPage{@include sec-default();
  .fleetLax{@include sec-default();padding-top:300px;
    background-image:url('../../assets/images/fleetSolo2.png');background-size:600px;background-position: center top; background-repeat: no-repeat;
    @include bp(tb) {padding-top:0px;background-image:none;}
  }

  .FleetDets2b{@include sec-default(); text-align: center; padding:40px 0 100px;position: relative;z-index: 0; 
    &::before{@include before();@include sec-bgimage('./images/clouds.jpg');background-size:cover;background-position:bottom center;}
    &::after{@include after();background-color:rgba($white,.9);left:0;border-top:100px solid $fa;}
    
    .floatImg{margin-bottom: 0px; width:80%;max-width:450px;display:none;}

    .box{display: inline-block; width:30%;
      img{height:35px; width:auto;margin-bottom:2px;}
    }
    .box1,.box2{display: inline-block;}
    .box1 {float:left;}
    .box2{margin-top:130px;width:33%;}
    .box3 {float:right;}
    .mobileBox{display: none;}

    p span:not(.red){text-transform: capitalize;font-weight: 600;}
    p span.red{font-style: italic;}
    p.h4{margin:15px 0 20px;font-size: 1.3rem;font-weight:bold;color:$red;}
    p.h4 span{background-color:rgba($white,.7);}
   
   
    @media (min-width:1025px){p.awk{max-width:400px; @include center-block();}}

    @media (min-width:1025px) and (max-width:1600px){
      .inside{width:95%;}
      .box{width:32%;padding:0 10px;}
    }

    @include bp(tl) {padding-bottom:60px;
      .box2{margin-top:100px;}
    }

    @include bp(tw){br.twHide{display: none;}}

    @include bp(tb) {padding:0 0 40px;
      .inside{width:90%;}
      .floatImg{@include center-block();}
      p.h4{margin:10px 0 20px;}

      .box{padding:0;}
      .box{text-align: left;
        img{display: none;}
        p{position: relative;z-index: 0;}
        p::before{@include before();width:50px;@include sec-bgimage('./images/MARCsymbol.png');
          opacity: .2;left:-15px;top:-5px;background-position: top center;
        }
        p.nobg::before{display: none;}
      }
      .box1{width:40%;}
      .box2{width:52%;margin-top:0;margin-right:-2%;}
      .box3{margin-top:10px;@include sec-default();
        img{margin-left:10%;}
      }
      .fwBox{display: none;}
      .mobileBox{display: block;}

      p{font-size:17px;}
    }

    @include bp(br) {
      .inside{max-width:730px;}
      p.h4{font-size: 1.2rem;margin-bottom:10px;}
      .floatImg{max-width:400px;}

      .box1,.box2,.box3{@include sec-default();}
      .spacer2{display: none;}
      p:not(.nobg){margin-top:15px;}
    }

    @include bp(md) {
      .inside{max-width:100%; width:90%;}
    }
  
    @include bp(og) {padding-bottom:20px;}
  }
}

.StackLayout.Fleet {padding-bottom:0;
  // &::after{@include after();background-color:$fa; height:100px;bottom:-100px;left:0;}
  p.h3{font-size:1.2rem;width:85%;}
  p.h3 b{font-weight:600;}

  .scStory{
    &::after{height:20px;}
    .storyWrap{position: relative;}
    .storyImage{right:-8%;width:69%;}
    .storyText{margin-top:4%;}
  }

  .scGrid{margin-bottom: 0;
    p.h4{font-weight:bold;font-size: 1.25rem;color:$red;margin-top:-40px;}
    p:not(.h4){margin-bottom:20px; padding-left:5px; position: relative; z-index: 0; line-height: 1.4em; 
      &::before{@include before(); width:5px; height:5px; border-radius: 50%; background-color:$red; top:50%; margin-top:-2.5px; left:-12px;}
    }
    p b{font-weight:500;}

    .box{width:32%;}
    .item{width:32%;margin-bottom: 20px;}
    .item.i1{width:31%;float:left;margin-left: 0;margin-top:10px;}
    .item.i2{margin-left:5%; margin-top:0;}
    .item.i3{float:right;margin-right:-4%; margin-top:0;}
  }
  
  .scGrid.g1{padding:0;}
  .scGrid.g2{padding-bottom:0px;}

  @include bp(tw){padding-top:40px;
    .med2Def,.medDef{width:940px;}
    
    p.h3{width:95%;font-size:1.1rem;}
    .scStory{margin-bottom:30px;
      .storyText{width:40%; margin-top:2%; margin-top:0;}
      .storyImage{right:0;width:60%;}
    }

    .scGrid{
      .item.i2, .item.i3{margin-top:-35px;}
    }
    .scGrid.g2{padding-bottom:20px;}
  }
  @include bp(tb){padding-top:30px;
    p.h3{width:100%;font-size:1rem;}
    .scStory{margin-bottom:20px;
      .storyText{margin-left:0;}
      .textbox{padding-left:12px;}
      .storyImage{right:0;width:60%;}
    }

    .scGrid {
      // p{background-color:transparent;}
      p.h4{font-size: 1.1rem;margin-top:-25px;margin-bottom: 5px;}

      .item.i1{width:37%;}
      .item.i2, .item.i3{width:60%;margin:0;float:right;}
    }
  }

  @include bp(br){padding-top:15px;
    .med2Def{width:90%; max-width:750px;}
    .medDef{width:90%; max-width:700px;}
    
    .scStory{margin-bottom:0px;
      .storyText{@include sec-default(); margin:0 0 10px;}
      .textbox{padding-left:0px;}
      .siMobile{width:80%;}
    }

    .scGrid.g1{padding-top:20px;}
    .scGrid.g2{padding-bottom:5px;}
    .scGrid {
      .box{@include sec-default();
        // p:not(.h4){display: inline-block;}
        // p:nth-child(2){width:39%; float:left;}
        // p:nth-child(3){width:60%; float:right;}
      }
      p.h4{margin:0px 0 15px; text-align: center;}

      .item.i1,.item.i2,.item.i3{@include sec-default(); margin:0;}
    }
  }

  @include bp(oc){
    .medDef{width:85%}
    .scGrid .box p:not(.h4){@include sec-default();}
  }
}