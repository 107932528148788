@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.BaseInfo{@include sec-default();overflow:hidden;position:relative;z-index:2;margin:0;padding:70px 0;
  .shBody{width:100%;display:flex;align-items:center;justify-content:space-between;position:relative;z-index:2;}
  .content{width:35%;margin:0 3% 0 -4%;padding:0 20px 0 30px;border-left:2px solid $red;position:relative;z-index:2;}
  .right{margin-right:-2%;}

  p,a{line-height:1.3em;}
  p{@include sec-default();margin:10px 0;
    b{text-transform:uppercase;letter-spacing:2px;font-size:15px;font-weight:bold;font-family:$boldFont;}
    span{font-size:15px;line-height:1.2em;font-weight:500;}
  }
  p.first{margin-top:0;}

  @include bp(tw) {padding:30px 0 60px;}
  @include bp(tb) {padding:35px 0 170px;
    .content{padding:0 0px 0 20px;margin-left:-2%;}
  }
  @include bp(br){padding:10px 0 150px;
    .medDef{max-width:650px;}
    .shBody{display:block;}
    .content{width:95%;margin:0 0 0 5%;float:left;}
    .right{@include sec-default();margin:30px 0 0;}

    .group{display:inline-block;width:45%;}
    .g1{float:left;}
    .g2{float:right;}
  }

  @include bp(md){padding:0px 0 130px;}
  
  @include bp(og){margin-top:-20px;padding-bottom:100px;
    .content{max-width:300px;@include center-block();border-left:none;
      &::before{@include before();height:460px;width:2px;left:-10px;background-color:$red;}
    }
    .group{@include sec-default();}
  }


  &.Andy{padding-top:20px;
    .medDef{max-width:300px;}
    .content{margin-left:auto;margin-right:auto;width:100%;padding-right:0;}

    @include bp(tb){padding:10px 0 60px;}
    @include bp(br){padding:0px 0 50px;
      .group{@include sec-default();}
    }
    @include bp(og){padding:0px 0 50px;
      .content{padding-bottom:0;}
      .content::before{height:300px;}
    }
    @include bp(oc){padding:30px 0 50px;}
  }


  &.XXX{
  @media (max-width:1400px){
    .shBody::after{right:-5%;}
  }
  @include bp(tw) {margin:30px 0;padding:20px 0 40px;
    .content{margin-left: -5%;}
  }
  @include bp(tb) {margin:0px;padding:30px 0;
    .shBody::after{width:65%;}
    .textBox p{margin:10px 0 15px;}
  }

  @media (max-width:959px){
    .shBody::after{width:62%;}
    .textBox{padding-left:25px;margin-left:-10px;
      p{font-size:16px;margin:6px 0 15px;}
    }
  }

  @include bp(og) {text-align: center; padding:25px 0 0;
    // &::after{width:100%;transform: skewY(-4deg); height:140px;bottom:100%;}
    // ?? &::before{@include after();height:20%;left:0;background-color:$white;}

    .shBody{padding-bottom:0;}
    .shBody::after{display: none;}
    .content{@include sec-default(); margin-left:0;max-width: 100%;}
    .right{@include sec-default();margin-top:15px;}
    .textBox{border-left:0;padding:0;margin-left: 0;
      p:last-child{margin-bottom:0px;}
    }
  }
  @include bp(sm) {
    .shBody{padding-top:20px;}
  }
  }
}

.BaseInfo.Hugo{
  @media (min-width:1024px){.medDef{max-width:900px;}}
}



// .BaseInfo.Texas .shBody::after{background-image: url("../../assets/images/tki/TKIgroup.jpg");}
// .BaseInfo.Dakota .shBody::after{background-image: url("../../assets/images/rapidcity.jpg");}
// .BaseInfo.Hugo .shBody::after{background-image: url("../../assets/images/hugo.jpg");}
// .BaseInfo.Nebraska .shBody::after{background-image: url("../../assets/images/valentine.jpg");}

// .BaseInfo.Dakota, .BaseInfo.Nebraska{
//   // .inside{max-width:1080px;}
//   // .shBody::after{display:none;}
//   .shBody{display:flex;align-items:center;justify-content:space-between;}
//   .content{width:35%;max-width:100%;margin:0 3% 0 -4%;padding-right:20px;}
//   .right{display:block;margin-right:-2%;}
//   .textBox p.first{margin-top:0;}
// }