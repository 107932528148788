@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer {@include sec-default();position: relative; z-index: 0;background-color:$black; 
  &::before{@include before(); width:90px; height:90px; left:50%; margin-left:-45px; top:-45px; z-index: 1;
    background-image: url('../../assets/images/MARCsymbol.png');background-size:50px; background-position: center center; background-repeat: no-repeat;
  }
  .fWrap1b{@include sec-default();overflow: hidden;}
  .FooterBg {@include sec-default(); padding:50px 0 30px;position: relative;z-index: 1;}

  .topLinks, .socialRow,.tlHd, .tlList, .tlList a{@include sec-default();}
  .tlList{margin-top: 15px;}

  .topLinks{text-align: center;
    .tlCol{display: inline-block; margin:0 2%;vertical-align: top; width:20%; text-align: left;}
    .tlCol:first-child{float:left; margin-left: 0;}
    .tlCol:last-child{float:right;margin-right: 0;width:26%;}

    .tlHd{position: relative;z-index: 0; padding-bottom:10px;
      &::after{@include abs(); width:30px; height:3px; bottom:0; left:0; background-color:$red;}
      &,a{font-weight:bold; text-transform: uppercase; font-size: 16px;letter-spacing: 1px;}
    }

    .tlList a{font-size:16px;margin-bottom: 10px;}
    .tlList a.long{line-height:1.4em;}
    .marcLinkSm{margin-top:5px;padding-bottom:6px;background-color:$white;color:$red2;text-shadow:none;
      &:hover{background-color:$white !important;color:$red2 !important;}
    }
  }

  .mobNav{display: none;}
  .ftDrawer{@include sec-default();text-align:center; margin-bottom: 10px;
    span.noFt{display: none;}
    a,p{color:$white;margin-bottom:0;font-weight:600;}
    span{margin-bottom:0 !important;}
    button{width:150px;padding:10px 20px;background-color:$red;border-radius: 0;}
  }

  .socialRow{text-align: center;margin:15px 0 20px;margin:25px 0 15px; padding:20px 0; border-top:1px solid rgba($white,.5);border-bottom:1px solid rgba($white,.5);}
  &, p, a{color:$white;}

  .socialRow{border-bottom: 0;padding-bottom: 0;}
  .FooterBtm{@include sec-default();text-align: center;position: relative;z-index:0;
    &::after{@include after();right: 0;background-color:$red;transform-origin: bottom left;height:130px;transform: skewY(4deg);}
  }
  
  .fbLogo{float:left;max-width: 250px; margin-left: 4%;margin-bottom:10px;}
  .copyHs2{@include sec-default();
    p{font-size:13px;letter-spacing: 1px;display: inline-block;margin-bottom: 0;}
    br{display:none;}
  }

  @media (min-width: 1024px){.copyHs2{position: absolute;bottom:5px;width:100%;right:0;}}
  @include bp(tl){
    .copyHs2{text-align: right;padding-right:5%;}
    .fbLogo{margin-bottom:5px;}
  }

  @include bp(tw){
    .fbLogo{margin-left:2%;}
    .copyHs2{padding-right:2%;}
  }

  @include bp(tb){
    .insideNrw{width:750px;}

    .topLinks{
      .tlCol{margin:0 1%;}
      .tlHd{padding-bottom:5px;}
      .tlCol:last-child{width:30%;}
    }
  }

  @include bp(br){
    .FooterBtm::after{height:138px;}
    .fbLogo{@include center-block();max-width:220px;margin-bottom:0;}
    .copyHs2{text-align: center;padding:0;}
  }
  
  @include bp(md){
    .insideNrw{width:90%;}
    .topLinks{
      .tlCol:first-child{width:19%;}
      .tlCol:last-child{width:31%;}
    }
  }

  @include bp(og){
    .insideNrw{width:95%;max-width: 550px;}
    .topLinks{
      .tlCol:not(:last-child){display: none;}
      .tlCol:last-child{@include sec-default();text-align:center;
        .tlHd, .tlList a.noOg{display: none;}
      }
    }
    .mobNav{@include sec-default();}

    .FooterBtm::after{height:150px;}
    .copyHs2{margin:15px 0 2px;
      br{display:block;}
      p{margin:0;line-height:1em;}
    }
  }  

  @include bp(sm){
    .copyHs2{margin-bottom:5px;padding:0 5px;
      br{display: none;}
    }
  }  
}