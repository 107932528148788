@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.NewsPage{
  .PageIntroNews2{@include sec-default();padding:20px 0 0px; text-align: center;
    p{font-size:19px;}
  }

  .newsWrap{@include sec-default(); text-align:center; padding:30px 0 100px;}
  .newsItem{display:inline-block; width:30%; margin:15px 1%; position:relative;z-index:0;@include sec-bgimage2();background-size:cover;padding:0 15px;
    display:inline-flex;height:296px;align-items:center;justify-content:center;vertical-align:top;

    &::before{@include before(); background-color:rgba($black,.75);}    
    &::after{@include after(); bottom:15px;width:30px;right:15px;@include sec-bgimage('./images/MARCsymbolW.png');background-position: bottom center;opacity: .9;}
    &:hover::before{background-color:rgba($black,.6);@include ease(.3s);}
  }

  .newsTitle{position:relative;z-index:2;line-height:1.4em;font-size:20px;color:$white; text-shadow:0px 1px 2px rgba($black,.6); font-weight:600;}
  .niCta1, .niCta2, .niCta3{
    &::before{background-image: linear-gradient($red, $red3);opacity:.88;}
    .textLink{color:$black;}
    p{color:$white;margin-bottom:20px;font-weight:500;}
  }
  .newsItem.niCta2{width:60%;background-image:url('../../assets/images/newsCTA.jpg');padding:0px 100px;}
  .newsItem.ctaFull{width:95%;
    p{max-width:660px;@include center-block();}
  }

  @media (min-width:1600px) {.insideN{width:80%;}}
  @include bp(tl) {
    .newsItem{padding:0 10px;}
    .newsTitle{font-size:18px;}
  }
  @include bp(tw) {
    .insideN{width:95%;}
    .newsItem.niCta2 p,.newsItem.niCta1 p{font-size:17px;}
  }
  @include bp(tb) {
    .PageIntroNews2{padding:10px 0;}
    .newsWrap{padding:0px 0 50px;}
    .insideN{max-width:750px;}
    .newsItem{width:48%;padding:0 15px;}
    .newsItem.niCta2,.newsItem.ctaFull{width:98%;height:auto;padding:45px 60px 50px;}
  }

  @include bp(og) {
    .newsWrap{padding:0px 0 40px;}
    .insideN{max-width:600px;}
    .newsItem{width:100%;padding:0;margin:10px 0;height:185px;height:auto;padding:30px 0;}
    .newsTitle{width:60%;}
    .newsItem.niCta p,.newsItem.niCta1 p{font-size:16px;width:90%;max-width:480px;@include center-block();}
    .marcLinkRev{font-size:14px;padding:8px 20px;}

    .newsItem.niCta2 {padding:35px 30px 40px;}
    .newsItem.ctaFull p{max-width:100%;}
  }

  @include bp(oc) {
    .PageIntroNews2{padding:10px 0 5px;}
    .insideN{max-width:100%;}
    .newsTitle{font-size:17px;}
  }
  
  // @include bp(sm) {
    // .newsTitle{width:90%;}
  // }
}