@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.FeedbackPage{@include sec-default();
  .FeedbackBody{@include sec-default();padding:20px 0 120px;}
  .fbIntro{text-align:center;}
  .linkRow{@include sec-default();margin:20px 0 50px;}

  .formImg,.ilForm{display: inline-block;}
  .formImg{float:left;width:44%;}
  .ilForm{width:60%;margin-left:-4%;margin-top:5%;
    background-color: $fa; padding:30px; box-shadow: 0px 10px 25px rgba($black,.1);
  }

  .textLink{margin:0 20px 10px;display:inline-block;line-height:1.2em;color:$red2;font-size:19px;
    span{font-size:15px;font-weight:normal;}
  }

  @include bp(tw) {
    .fbIntro .medDef{max-width:850px;}
    .ilForm{margin-top:3%;}
  }

  @include bp(tb) {
    .FeedbackBody{padding:10px 0 80px;}
    // .linkRow{margin-bottom:25px;}
    .formImg{width:40%;}
    .ilForm{width:66%;margin-left:-6%;margin-top:5%;}
  }

  @include bp(br) {
    .FeedbackBody{padding-top:0px;}
    .fbIntro p,.textLink{font-size:17px;
      span{font-size:14px;}
    }
    .formImg{width:44%;}
    .ilForm{width:62%;}
  }

  @include bp(og) {
    .FeedbackBody{padding:10px 0 50px;}
    .linkRow{margin:10px 0;}
    .textLink{margin-bottom:30px;}

    .formImg{display:none;}
    .ilForm{@include sec-default(); margin:0; position: relative; z-index: 0; padding:20px 30px;
      @include sec-bgimage("./images/uniformMobile.jpg");background-size:cover;box-shadow: 0px 10px 25px rgba($black,.3);
      &::before{@include before(); background-color:rgba(254,248,248,.96);}
    }
  }

  @include bp(oc) {
    .linkRow{margin-top:0;}
    .fbIntro p,.textLink{font-size:16px;}
    .textLink{max-width:150px;@include center-block();margin:15px auto 20px;line-height:1.1em;
      // &:last-child{margin-right:0;}
    }
    // .textLink{@include sec-default();margin:}
  }
}