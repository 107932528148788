@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.cFormWrap {@include sec-default();
  p.successSub{color:$blue;}
  p.errorSub{color:$red;}

  .customer,.property,label, .cfield {@include sec-default();}

  .formGroup:not(.fgdets){display: inline-block; margin-bottom: 15px; width:48%; 
    &:nth-child(2n+2){float:right;}
  }
  .fgdets,.btnRow{@include sec-default();}
  
  label{font-size:.95rem;}
  input, textarea, select {border:none; border-bottom:2px solid rgba($blue,.25); padding:2px 2px 2px 5px; font-weight: 500; margin-bottom:20px;}
  input, select {min-height:35px;}
  textarea {min-height:125px;padding-top:5px;}
  .marcLink{font-size:16px; min-height: auto; padding: 10px;width:135px; float:right; margin-top:20px;}

  @include bp(br) {
    .formGroup:not(.fgdets){@include sec-default(); margin:0 0 10px;} 
  }

}