@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.MedicalForms{@include sec-default();
  .mfBody{@include sec-default();padding:40px 0 30px;}
  .formSec{@include sec-default();}

  p.strong{@include sec-default();margin:25px 0 30px;}

  @include bp(tb){
    .mfBody{padding:10px 0 0px;}
    .med2Def{max-width:720px;}
  }

  @include bp(nn){.mfBody{padding:0px 0 60px;}}
  @include bp(og){
    .mfBody{padding:10px 0 60px;}
    p.smSmall{font-size:16px;}
    p.strong{margin:5px 0 30px;}
  }
}