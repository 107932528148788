@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.SkewPlax, .SkewPlaxTall{@include sec-default();@include sec-bgimage2();background-image:url('../../assets/images/gBg.jpg');
  position: relative;z-index: 0;text-align: center;overflow:hidden; margin-top:-50px;padding-top: 160px;

  &::before{@include before(); background-color:$black;opacity: .7;}
  &::after{@include before();margin-top:-160px; height:160px;bottom: 0;right: 0;background-color:$white;transform: skewY(-5deg);transform-origin: top right;}
  
  .spWrap{@include sec-default();}
  &.default .spWrap{margin:5% auto 9%;}
  &.tall .spWrap{margin:9% auto 15%;}
  &.noSkew::after{display: none !important;}

  &,h1,h2,h3,p, a{color:$white;text-shadow:0px 1px 2px rgba($black,.35);}
  h1{color:$white;text-shadow:0px 1px 2px rgba($black,.65);font-size:2.4rem;margin-bottom: 0;}
  h1 span{color:$red;}
  h2,h3{letter-spacing: 6px; text-transform: uppercase;font-size: 1.6rem;}
  h3{line-height: 1.3em;}
  p{font-size: 20px;margin-top:15px; font-weight:400;}
  &, a{font-weight:bold; font-size: 21px;}
  .textBox a:first-child{margin-right:20px;}

  .cta{@include sec-default(); text-align: center;
    p{display: inline-block; font-weight: bold;margin:0 2% 15px; float:none;}
  }

  .StatBoxes{@include sec-default();text-align:center;margin-top:40px;
    & > div:first-child{
      display: flex; align-items: center; justify-content:center;
      div{display:inline-block;margin:0 15px;}
    }
    p{line-height:1em; font-size:18px; margin:0 0 5px;}
    p b {font-size:30px; font-weight:800;}
    p span{font-weight:600;}
  }

  @media (min-width:1830px) {
    &::after{margin-top:-250px; height:250px;}
  }

  @include bp(tw){
    &.tall .spWrap{margin:5% auto 11%;}
  }

  @include bp(tb) {padding-top:130px;
    &::after{margin-top:-150px;}
    &.tall .spWrap{margin:3% auto 10%;}

    .med3Def{max-width:800px;}

    h1{font-size:2rem;}
    h2,h3{letter-spacing: 6px;font-size: 1.3rem;}
    &, p, a{font-size: 19px;}

    .StatBoxes{
      // & > div:first-child{padding:20px 30px 35px;}
      p span{font-size: 17px;}
      p b{font-size: 28px;}
    }
  }

  @include bp(br){padding-top:100px;
    &.default .spWrap{margin:3% auto 7%;}
    &.tall .spWrap{margin:5% auto 5%;}

    .StatBoxes{
      p b{font-size: 25px;}
    }
  }

  @include bp(md) {
    h1{font-size:1.6rem;}
    h2,h3{letter-spacing: 6px;font-size: 1.15rem;}
    &, p, a{font-size: 18px;}

    .StatBoxes{margin-top:20px;
      p{font-size:17px;}
      p b{font-size:22px;}
    }
  }

  @include bp(og) {background-size:cover;padding-top:60px;
    &.default .spWrap{margin:3% auto 6%;}
    &.tall .spWrap{margin:5% auto 7%;}

    .med3Def{max-width:600px;}
    h1{font-size:1.4rem;}
    h2,h3{letter-spacing:4px;font-size: 1.05rem;}
    &, p{font-size: 17px;}
    a{font-size: 16px;}
  }

  @include bp(oc) {
    h1{font-size:1.2rem;}
    h2,h3{letter-spacing:4px;font-size: 1rem;}

    // .StatBoxes > div:first-child{
    //   display: block;
    //   div{width:40%; margin:0 15px;}
    // }
  }

  @include bp(ph) {
    .cta p{margin:0 0 10px;width:100%;}
  }
}


.SkewPlax.Home{background-image:url('../../assets/images/HomeHero.jpg'); background-position: top center;
  &::before{opacity: .6;}
  p{margin-top:0;}
  p span{font-size:16px;}
  h2{margin-bottom:35px;}

  @media (min-width:1400px){min-height:940px;.spWrap{margin-top:18%;}}
  @media (max-width:1399px){.spWrap{margin:12% auto 18%;}}
  @include bp(sm){br{display: none;}}
}
.SkewPlax.MidHome{background-image:url('../../assets/images/statBg.jpg');background-size:cover;min-height:800px;
  // .spWrap{margin:2% auto 6%;}
  // p{font-size:28px;}
  // h4{color:$white;font-size:30px;font-weight:bold;}
  // img{margin:20px auto 20px;}


  &::before{opacity: .8;}
  .insideNrw{max-width:650px;}
  h3{font-size:30px;margin-bottom:0px;text-transform: none;letter-spacing: 0;font-weight: bold;line-height: 1.5em;}
  li{width:100%;}
  p{font-size:22px;font-weight:500;}
  p span{font-size:17px;font-style:italic;font-weight:300;}
  img{width:30px;@include center-block();margin:25px auto 12px;}

  button{color:$white;padding-top:0;margin-top:0;opacity: .6;margin-right:-30px !important;margin-top:-40px;}
  button:hover,button:focus{color:$white !important;}
  button:hover{opacity: .4;}
  svg{height:18px;}
  
  @media (min-width:1880px) {min-height:670px;
    // .spWrap{margin:7% 0;}
  }
  @include bp(tb){min-height: 600px;}
  @include bp(br){min-height:500px;
    h3{font-size:25px;}
    p{font-size:20px;}
  }
  @include bp(og){min-height:450px;
    .spWrap{margin:5% auto 4%;}
    h3{font-size:21px;}
    p{font-size:18px;}
  }
}

.SkewPlax.About{background-image:url('../../assets/images/AboutHeader.jpg');
  h2{letter-spacing:1px;margin-bottom:0;}
  h1{line-height:1.4em !important;}
  // p{display: inline-block;}
  p{letter-spacing:.5px;font-weight:600;text-shadow:0px 1px 2px rgba($black,.75);line-height:1.4em;}
  @media (min-width:1025px){h2{font-size:1.4rem;}}
  @include bp(br){
    p{font-size:17px;margin-top:0px;}
    h3{margin-bottom: 10px;}
  }
}
.SkewPlax.Bases{background-image:url('../../assets/images/basesHeader.jpg');
.med3Def{max-width:750px;}
}
.SkewPlax.comingSoon{height:88vh;
  // 
  h1{position: relative;padding-bottom:35px;
    &::after{@include after();left:0;background-image:url("../../assets/images/MARCsymbolW.png");background-size:20px;background-position:bottom center;background-repeat:no-repeat;}
  }
  .spWrap{margin-top:160px;}

  @include bp(tx){height:700px;background-size:cover;
    .spWrap{margin-top:60px;}
  }
  @include bp(br){height:100%;min-height:600px;}
  @include bp(og){min-height:470px;min-height:auto;
    .spWrap{margin:60px 0;}
  }

  @media (max-width:570px){
    &::before{opacity: .8;}
    .spWrap{margin:30px 0 60px}
  }
}

.SkewPlax.Leadership{background-image:url('../../assets/images/ribbon1.jpg');background-size:cover;padding-top:0;margin-top:0;
  &::after{top:auto;transform-origin: bottom right;}
  p{line-height:1.4em;margin:0;}

  @media (max-width:1250px){padding-bottom:30px;}
  @include bp(br){padding:0;}
  @include bp(og){
    &::after{height:90px;}
    .spWrap{margin-bottom:0;}
  }
}